import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FacetSearchRequest, FacetSearchResponse } from 'services/Api';
import {
  RequestState,
  generateRequestState,
  setRequestError,
  startRequest
} from 'utils/reducerHelpers';

type InitialStateType = RequestState & {
  event: {
    facets: Record<string, FacetSearchResponse>;
    searchParams: FacetSearchRequest;
  };
};

const initialState: InitialStateType = {
  request: generateRequestState(['listEventFacets'], []),
  event: {
    facets: {},
    searchParams: {}
  }
};

export const selectEventFacets = (state: any) => state.facet.event.facets;
export const selectListEventFacetsRequestState = (state: any) =>
  state.facet.request.listEventFacets;

const facetSlice = createSlice({
  name: 'facet',
  initialState,
  reducers: {
    listEventFacets(state, action: PayloadAction<FacetSearchRequest>) {
      startRequest(state.request.listEventFacets);
      state.event.searchParams = action.payload;
    },
    listEventFacetsError(state, action) {
      setRequestError(state.request.listEventFacets, action.payload);
    },
    eventFacets: {
      reducer(state, action: PayloadAction<{ facetName: string; facets: FacetSearchResponse }>) {
        const { facetName, facets } = action.payload;
        state.event.facets[facetName] = facets;
        state.request.listEventFacets.loading = false;
      },
      prepare(facetName: string, facets: FacetSearchResponse) {
        return {
          payload: {
            facetName,
            facets
          }
        };
      }
    }
  }
});

export const { listEventFacets, listEventFacetsError, eventFacets } = facetSlice.actions;
export default facetSlice.reducer;
