import { call, put, takeLatest, select } from 'redux-saga/effects';
import Api, { Member } from 'services/Api';
import { AuthState, currentSession } from 'services/Auth';
import {
  accessToken,
  fetchCurrentUser,
  currentUser,
  impersonateUser as impersonateUserAction,
  state,
  selectImpersonatedUser
} from 'redux/reducers/auth';
import {
  ready,
  selectBackgroundTasks,
  startBackgroundCurrentAccessRefresh,
  stopBackgroundCurrentAccessRefresh,
  startBackgroundTokenRefresh,
  stopBackgroundTokenRefresh,
  stopBackgroundProfileAndListValueRefresh
} from 'redux/reducers/app';
import { resetStore as resetStoreAction, startup as startupAction } from 'redux/reducers';
import { takeIdentity } from './authSagas';
import log from 'loglevel';
import { LOCAL_STORAGE_KEYS } from 'constants/LocalStorageKeys';
import { organizationId } from '../../tools/organizationTools';

// TODO: use correcct typing
export function* resetStoreSaga() {
  yield put(resetStoreAction());
}

// TODO: use correcct typing
export function* currentAccessSaga(language?: string) {
  try {
    const requestParams = yield call(getRequestParams);
    yield put(fetchCurrentUser());
    let user = yield call(Api.members.getMyAccess, requestParams);
    if (language && language !== user.language) {
      yield call(Api.members.updateMember, user.memberId, { language } as Member, requestParams);
      user = { ...user, language };
    }
    yield put(currentUser(user));
  } catch (exception) {
    log.warn('failed to load current access', exception);
    throw exception;
  }
}

export function* startup() {
  try {
    // TODO: load prefered language if set
    const session = yield call(currentSession);
    if (session) {
      yield put(accessToken(session.getAccessToken().getJwtToken()));
      // yield call(setApiSecurtityData, session)
      yield call(currentAccessSaga);
      if (localStorage.getItem(LOCAL_STORAGE_KEYS.impersonatedUserId) !== null) {
        const impersonatedUserId: string | null = JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_KEYS.impersonatedUserId)!
        );
        if (impersonatedUserId) {
          yield call(takeIdentity, impersonateUserAction(impersonatedUserId));
        }
      }
      yield put(state(AuthState.SignedIn));
      yield put(ready(true));
      yield call(startBackgroundTasks);
    }
  } catch (exception) {
    log.warn('startup', exception);
    yield put(state(AuthState.SignIn));
    yield put(ready(true));
  }
}

export function* getRequestParams() {
  const token = yield select(state => state.auth.accessToken);
  const impersonatedUser = yield select(selectImpersonatedUser);
  const requestParams = {
    headers: {
      Authorization: 'Bearer ' + token,
      'Organization-Id': organizationId
    }
  };
  return !impersonatedUser
    ? requestParams
    : {
        ...requestParams,
        headers: {
          ...requestParams.headers,
          'Impersonate-User-Id': impersonatedUser.userId
        }
      };
}

export function* startBackgroundTasks() {
  yield put(startBackgroundTokenRefresh());
  yield put(startBackgroundCurrentAccessRefresh());
}

export function* stopBackgroundTasks() {
  const backgroundTasks = yield select(selectBackgroundTasks);
  if (backgroundTasks.refreshToken) yield put(stopBackgroundTokenRefresh());
  if (backgroundTasks.refreshCurrentAccess) yield put(stopBackgroundCurrentAccessRefresh());
  if (backgroundTasks.refreshProfileAndListValue)
    yield put(stopBackgroundProfileAndListValueRefresh());
}

const sagas = [takeLatest(startupAction.type, startup)];

export default sagas;
