import _ from 'lodash';
import { EntityAdapter, EntityState } from '@reduxjs/toolkit';
import { SearchRequest } from 'services/Api';
import { Error } from 'services/Error';

export type RequestConfig = {
  error: Error;
  loading: boolean;
  refreshing?: boolean;
};

export type RequestState = {
  request: {
    [k: string]: RequestConfig;
  };
};

export interface SearchState<T> {
  addedItems: EntityState<T>;
  facets: Record<string, Record<string, number>>;
  loadedItems: EntityState<T>;
  itemCount: number;
  totalCount: number;
  searchParams: SearchRequest;
  savedSearch: Record<string, any>;
}

export function generateSearchState<T>(adapteur: EntityAdapter<T>): SearchState<T> {
  return {
    addedItems: adapteur.getInitialState(),
    facets: {},
    loadedItems: adapteur.getInitialState(),
    itemCount: 0,
    totalCount: 0,
    searchParams: {
      filters: []
    },
    savedSearch: {}
  };
}

export function generateRequestState(actions: string[], actionsWithRefresh: string[] | undefined) {
  const initialConfig = {
    error: undefined,
    loading: false,
    refreshing: false
  };
  return _.reduce(
    actions,
    (state: { [k: string]: RequestConfig }, action: string) => {
      state[action] = _.omit(
        initialConfig,
        !_.includes(actionsWithRefresh, action) ? ['refreshing'] : []
      );
      return state;
    },
    {}
  );
}

export function setRequestError(request: RequestConfig, error: Error) {
  request.error = error;
  request.loading = false;
  if (_.has(request, 'refreshing')) request.refreshing = false;
}

export function startRequest(request: RequestConfig) {
  request.loading = true;
  request.error = undefined;
}
