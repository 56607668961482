import _ from 'lodash';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import Api from 'services/Api';
import { handleMemberApiError, handleError } from 'services/Error';
import { selectAuthUser } from 'redux/reducers/auth';
import {
  searchMembers as searchMembersAction,
  listMembers as listMembersAction,
  members as membersAction,
  listMembersError as listMembersErrorAction,
  addMember as addMemberAction,
  moreLoaded as moreLoadedAction,
  memberAdded as memberAddedAction,
  addMemberError as addMemberErrorAction,
  updateMember as updateMemberAction,
  updateMemberError as updateMemberErrorAction,
  memberUpdated as memberUpdatedAction,
  deleteMember as deleteMemberAction,
  deleteMemberError as deleteMemberErrorAction,
  memberDeleted as memberDeletedAction,
  searchMemberLoadMore as searchMemberLoadMoreAction,
  activateMember as activateMemberAction,
  listRoles as listRolesAction,
  roles as rolesAction,
  MemberAddedPayload,
  SearchMembersPayload,
  selectMemberCount,
  selectMemberSearchParams
} from 'redux/reducers/member';
import { listValuesByProfileId as listValuesByProfileIdAction } from 'redux/reducers/profiles';
import { Member } from '@hbscloud/hekma-sdk';
import { currentAccessSaga, getRequestParams } from './appSagas';
import { getListValueByProfileId } from './profileSaga';

// TODO: translations
function* searchMembers(action: SearchMembersPayload) {
  try {
    let params: any = {
      length: 20
    };
    if (action && action.payload) {
      params = {
        ..._.omit(action.payload, 'refresh'),
        length: action.payload.length || 20,
        offset: action.payload.offset || 0,
        filters: action.payload.filters || []
      };
    }
    const requestParams = yield call(getRequestParams);
    const members = yield call(Api.members.searchMembers, params, requestParams);
    yield put(membersAction(members));
  } catch (exception) {
    console.warn(exception);
    yield put(listMembersErrorAction(handleError(exception)));
  }
}

function* searchMembersLoadMore(action: any) {
  try {
    const count = action.payload || 20;
    const memberCount = yield select(selectMemberCount);
    const memberSearchParams = yield select(selectMemberSearchParams);
    const params: any = {
      ...memberSearchParams,
      offset:
        memberSearchParams.offset !== undefined
          ? memberSearchParams.offset + memberCount
          : memberCount,
      length: memberSearchParams.length !== undefined ? memberSearchParams.length : count,
      filters: memberSearchParams.filters || [] // ensure filter is defined in the request, otherwise the result will be empty
    };
    const requestParams = yield call(getRequestParams);
    const members = yield call(Api.members.searchMembers, params, requestParams);
    yield put(moreLoadedAction(members));
  } catch (exception) {
    console.warn(exception);
    yield put(listMembersErrorAction(handleError(exception)));
  }
}

function* listMembers() {
  try {
    const requestParams = yield call(getRequestParams);
    const members = yield call(Api.members.getMembers, undefined, requestParams);
    yield call(listRoles);
    yield call(getListValueByProfileId, listValuesByProfileIdAction('clubsList'));
    yield put(membersAction(members));
  } catch (exception) {
    console.warn(exception);
    yield put(listMembersErrorAction(handleError(exception)));
  }
}

function* addMember(action: MemberAddedPayload) {
  try {
    const requestParams = yield call(getRequestParams);
    const member = yield call(Api.members.createMember, action.payload, requestParams);
    yield put(memberAddedAction(member));
  } catch (exception) {
    yield put(addMemberErrorAction(handleMemberApiError(exception)));
  }
}

function* activateMember(action: any) {
  try {
    const { id } = action.payload;
    const requestParams = yield call(getRequestParams);
    const updatedMember = yield call(
      Api.members.updateMember,
      id,
      { createUserIfNecessary: true } as Member,
      requestParams
    );
    yield put(memberUpdatedAction({ ...updatedMember, userId: 'temp' }));
  } catch (exception) {
    yield put(updateMemberErrorAction(handleMemberApiError(exception)));
  }
}

function* updateMember(action: any) {
  try {
    const { id, ...data } = action.payload;
    const currentUser = yield select(selectAuthUser);
    const requestParams = yield call(getRequestParams);
    const updatedMember = yield call(Api.members.updateMember, id, data as Member, requestParams);
    yield put(memberUpdatedAction(updatedMember));
    if (currentUser.memberId === updatedMember.id) {
      yield call(currentAccessSaga);
    }
  } catch (exception) {
    yield put(updateMemberErrorAction(handleMemberApiError(exception)));
  }
}

function* deleteMember(action: PayloadAction<string>) {
  try {
    const id = action.payload;
    const requestParams = yield call(getRequestParams);
    yield call(Api.members.deleteMember, id, requestParams);
    yield put(memberDeletedAction(id));
  } catch (exception) {
    console.warn(exception);
    yield put(deleteMemberErrorAction(handleMemberApiError(exception)));
  }
}

function* listRoles() {
  const requestParams = yield call(getRequestParams);
  const roles = yield call(Api.roles.getRoles, undefined, requestParams);
  yield put(rolesAction(roles));
}

const sagas = [
  takeLatest(searchMembersAction.type, searchMembers),
  takeLatest(listMembersAction.type, listMembers),
  takeLatest(addMemberAction.type, addMember),
  takeLatest(searchMemberLoadMoreAction.type, searchMembersLoadMore),
  takeLatest(activateMemberAction.type, activateMember),
  takeLatest(updateMemberAction.type, updateMember),
  takeLatest(deleteMemberAction.type, deleteMember),
  takeLatest(listRolesAction.type, listRoles)
];

export default sagas;
