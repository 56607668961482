import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import authReducer from './auth';
import appReducer from './app';
import memberReducer from './member';
import profileReducer from './profiles';
import fieldDefinitionReducer from './fieldDefinition';
import toastReducer from './toast';
import eventReducer, { EventReducerState } from './event';
import workflowRequestReducer from './workflowRequest';
import storageReducer from './storage';
import resourceReducer from './resource';
import facetReducer from './facet';
import listValueReducer from './listValue';
import creditTransactionReducer from './creditTransaction';
import monitoringReducer, { MonitoringReducerState } from './monitoring';
import roleReducer, { RoleReducerState } from './role';
import permissionReducer, { PermissionReducerState } from './permission';
import { organizationId } from '../../tools/organizationTools';

export interface RootState {
  app?: any;
  auth?: any;
  creditTransaction?: any;
  event: EventReducerState;
  facet?: any;
  fieldDefinition?: any;
  listValue?: any;
  member?: any;
  monitoring: MonitoringReducerState;
  permission: PermissionReducerState;
  profile?: any;
  resource?: any;
  role: RoleReducerState;
  storage?: any;
  toast?: any;
  workflowRequest?: any;
}

// TODO: replace all useDispatch, useSelector in project by useAppDispatch, useAppSelector
export const useAppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Combine all reducers.
const appReducers = combineReducers<RootState>({
  app: appReducer,
  auth: authReducer,
  creditTransaction: creditTransactionReducer,
  event: eventReducer,
  facet: facetReducer,
  fieldDefinition: fieldDefinitionReducer,
  listValue: listValueReducer,
  member: memberReducer,
  monitoring: monitoringReducer,
  permission: permissionReducer,
  profile: profileReducer,
  resource: resourceReducer,
  role: roleReducer,
  storage: storageReducer,
  toast: toastReducer,
  workflowRequest: workflowRequestReducer
});

export const resetStore = createAction<undefined>('resetStore');
export const startup = createAction<undefined>('startup');

const rootReducer = createReducer({} as RootState, builder => {
  builder
    .addCase(resetStore, (state, action) => {
      // we must set the app.ready to true otherwise we'll get stuck at the app loading screen
      // as the startup already happened
      return appReducers(
        {
          app: {
            ...state.app,
            organizationId,
            ready: true,
            backgroundTasks: {}
          },
          profile: state.profile
        } as RootState,
        action
      );
    })
    .addDefaultCase((state, action) => appReducers(state as RootState, action));
});

export default rootReducer;
