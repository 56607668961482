import {
  Api,
  CreditTransactionResponse,
  MemberResponse,
  RoleResponse,
  ProfileResponse,
  ListValueResponse,
  EventResponse,
  LogResponse,
  WorkflowRequestResponse,
  MediaResourceResponse,
  EssenceResponse,
  IFormatResponse,
  StorageResponse,
  OutputFormatResponse,
  PermissionResponse
} from '@hbscloud/hekma-sdk';
import { apiBaseUrl } from '../tools/organizationTools';

export const api = new Api({ baseUrl: apiBaseUrl });

export default api;

export type CreditTransaction = CreditTransactionResponse;
export type Member = MemberResponse;
export type Role = RoleResponse;
export type Profile = ProfileResponse;
export type ListValue = ListValueResponse;
export type Event = EventResponse;
export type Log = LogResponse;
export type WorkflowRequest = WorkflowRequestResponse;
export type MediaResource = MediaResourceResponse;
export type ClipFormat = IFormatResponse;
export type Storage = StorageResponse;
export type Essence = EssenceResponse;
export type OutputFormat = OutputFormatResponse;
export type Permission = PermissionResponse;

export type {
  AccessResponse,
  CreditTransaction as CreditTransactionData,
  DetailedStatus,
  EntityProfileResponse,
  Event as EventData,
  FacetFilter,
  FacetSearchRequest,
  FacetSearchResponse,
  FacetHits,
  FieldResponse,
  Filter,
  LiveControllerDetailedWsNotification,
  LogFile,
  MemberApprobation,
  MemberApprobationResponse,
  MonitoringResponse,
  MonitoringHeaderGroup,
  MonitoringHeader,
  MonitoringHeaderItem,
  MonitoringItem,
  Pagination,
  Tag,
  Role as RoleData,
  SearchRequest,
  SearchResponse,
  VideoFormat
} from '@hbscloud/hekma-sdk';
