import { all, call } from 'redux-saga/effects';

import appSagas from './appSagas';
import authSagas, { startRefreshTokenMonitor } from './authSagas';
import memberSagas from './memberSagas';
import eventSagas from './eventSagas';
import profileSagas from './profileSaga';
import resourceSagas from './resourceSagas';
import storageSagas from './storageSagas';
import workflowRequestSagas from './workflowRequestSagas';
import facetSagas from './facetSagas';
import listValueSagas from './listValueSagas';
import creditTransactionSagas from './creditTransactionSagas';
import monitoringSagas from './monitoringSagas';
import roleSagas from './roleSagas';
import permissionSagas from './permissionSagas';

export default function* rootSaga() {
  yield all([
    call(startRefreshTokenMonitor),
    ...appSagas,
    ...authSagas,
    ...memberSagas,
    ...eventSagas,
    ...profileSagas,
    ...resourceSagas,
    ...storageSagas,
    ...workflowRequestSagas,
    ...facetSagas,
    ...listValueSagas,
    ...creditTransactionSagas,
    ...monitoringSagas,
    ...roleSagas,
    ...permissionSagas
  ]);
}
