import { Path } from '@hbscloud/hekma-sdk';
import { replace } from 'lodash';

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);
type Source = { src: string; cookie: any; expirationDate?: string };

export const getCompatibleSource = (path: Path): Source => {
  const source = {
    src: path.url,
    cookie: path.signCookies,
    expirationDate: path.expirationDate
  };

  if (process.env.NODE_ENV === 'development' && source.src) {
    return {
      ...source,
      src: replace(source.src, /https:\/\/lfp(\.dev|\.demo)?\.(hbscloud\.tv|hbmediacloud\.tv)/, '')
    };
  }
  return source;
};
