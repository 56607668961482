import { createSlice, PayloadAction, createEntityAdapter } from '@reduxjs/toolkit';
import { Storage } from 'services/Api';

const storageAdapter = createEntityAdapter<Storage>({
  selectId: (storage: Storage) => storage.id
});

// TODO: find the right type
const storageSelector = storageAdapter.getSelectors<any>(state => state.storage.storages);

type Error = { code: string; message?: any } | undefined;

export const selectStorages = storageSelector.selectAll;
export const selectStorageById = storageSelector.selectById;

type StorageState = {
  request: {
    list: {
      loading: boolean;
      error: Error;
    };
  };
  storages: ReturnType<typeof storageAdapter.getInitialState>;
};

const initialState: StorageState = {
  request: {
    list: {
      loading: false,
      error: undefined
    }
  },
  storages: storageAdapter.getInitialState()
};

const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    listStorages(state, action: PayloadAction<undefined>) {
      state.request.list.loading = true;
    },
    listStoragesError(state, action: PayloadAction<Error>) {
      state.request.list.loading = false;
      state.request.list.error = action.payload;
    },
    storages(state, action) {
      storageAdapter.setAll(state.storages, action.payload);
      state.request.list.loading = false;
    }
  }
});

export const { listStorages, listStoragesError, storages } = storageSlice.actions;
export type { Storage } from 'services/Api';
export default storageSlice.reducer;
