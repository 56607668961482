import _ from 'lodash';
import moment from 'moment';
import { FacetFilter, Filter, Log, MediaResource, Tag } from 'services/Api';

export function getTagValueById(object: any, tagId: string, options?: { single?: boolean }) {
  if (!object) return undefined;
  const tag = object.tags?.find((tag: Tag) => tag.id === tagId);
  const single = _.get(options, 'single', true);
  return single ? _.get(tag, `${tag?.type}Values[0]`) : _.get(tag, `${tag?.type}Values`);
}

export function isNullOrUndefined(value: unknown) {
  return value === undefined || value === null;
}
export function isNotNullNorUndefined(value: unknown) {
  return isNullOrUndefined(value) === false;
}

// TODO: typing
export function parseLog(item: any) {
  const kewordTag = !item.tags ? [] : item.tags.filter((tag: Tag) => tag.id === 'keywords');
  const parsedLog = {
    ...item,
    keywords: kewordTag.reduce((keywords: string[], tag: Tag) => {
      return [...keywords, ...(tag.stringValues ? tag.stringValues : [])];
    }, [])
  };

  const logIconTag = item.tags?.find((tag: any) => tag.id === 'logIcon');
  if (logIconTag) {
    // for compability, it should be tag.id === "icon"
    parsedLog.icon = logIconTag.listValues[0].tags.find(
      (tag: any) => tag.id === 'icon' || tag.id === 'logIcon'
    ).linkValues[0];
  }

  return parsedLog;
}

/**
 * Visible logs are logs within the video duration
 * Log datetimeIn is an abolute date ie "2019-08-28T22:14:31.000Z"
 * To filter logs we need an abolute start date and an absolute end date (= abolute start date + duration)
 * Data we can expect from media resources are :
 * - resource.durationInMilliseconds             : (should always be there) video duration
 * - resource.logsTrackLink.offsetInMilliseconds : (should always be there) video start time relative to start of the day. Can be 0 it the match has not been sync with logs track
 * - resource.logsTrackLink.initialOffsetDate    : (should be there but rare at the time of writing) video start date (without time), needed to handle logs spanning multiple days
 * @param logs          logs sorted by date
 * @param mediaResource the media resource the logs have to be filtered for
 */
export function getVisibleLogs(logs: Array<Log>, mediaResource: MediaResource) {
  if (!mediaResource || logs.length === 0) return logs;

  const logsTrackLink = mediaResource.logsTrackLinks?.[0];

  // if there is no offset, return all logs
  if (!logsTrackLink || (logsTrackLink && typeof logsTrackLink.offsetInMilliseconds !== 'number'))
    return logs;

  // logs are already sorted by date, take the first one is enough
  const firstLogDate = moment.utc(logs[0].datetimeIn);
  const startOfDayDateOfFirstLog = firstLogDate.clone().startOf('day');
  // try to retrive the video start absolute date
  const offset = logsTrackLink.offsetInMilliseconds;

  // when initialOffsetDate is not defined use startOfDayDateOfFirstLog
  const initialOffsetDate = logsTrackLink?.initialOffsetDate
    ? moment.utc(logsTrackLink?.initialOffsetDate).valueOf()
    : startOfDayDateOfFirstLog.valueOf();

  // get video start aboslute date and time by putting together video start date (logsTrackStartDate) and video start time from start of the day (offset)
  const logsTrackStartDate = moment.utc(initialOffsetDate).valueOf();
  const logsTrackStart = logsTrackStartDate + offset;

  // if video duration is not set, all logs will be filtered out
  // except if there is logs track link (or no offset), the offset will be computed from the first log, so the first log will always meet the filter criteria
  // TODO: validate that's the behavior we want, we have to choose betweend
  // - keep the current behvior (when a new log will be added in admin media and log, it will not appear)
  // - to filter every logs
  // - to not filter logs (when a new log will be added in admin media and log, it will not appear)
  const mediaResourceDuration = mediaResource.durationInMilliseconds || 0;
  return logs.filter((log: any) => {
    return (
      moment.utc(log.datetimeIn).valueOf() >= logsTrackStart &&
      moment.utc(log.datetimeIn).valueOf() <= logsTrackStart + mediaResourceDuration
    );
  });
}

export function getEstimatedClipSize(bitrateInMB: number, durationInSeconds: number) {
  return Math.round((bitrateInMB * durationInSeconds) / 8);
}

export function getMediaResourcesWithFullPath(resources: MediaResource[] | undefined) {
  if (!resources) return [];
  const resourcesWithFullPath: MediaResource[] = resources.filter((resource: MediaResource) => {
    return resource.essences && resource.essences.some((essence: any) => !!essence.fullPath);
  });
  return resourcesWithFullPath.map((resource: MediaResource) => {
    const videoSource = _.get(getTagValueById(resource, 'videoSource'), 'value');
    const videoContent = _.get(getTagValueById(resource, 'videoContent'), 'value');
    return {
      ...resource,
      cameraName: _.filter([videoSource, videoContent], v => !_.isUndefined(v)).join(' - ')
    };
  });
}

export function mapOptionsToFilters(
  name: string,
  options: Array<{ label: string; value: string }>,
  initialFilters: Array<Omit<Filter, 'type'>>
) {
  let filters: Omit<FacetFilter, 'type'>[] = [];
  if (!_.isEmpty(options)) {
    const optionFilter: Omit<FacetFilter, 'type'>[] = [
      {
        name,
        operator: '=',
        value: options.map(option => option.value)
      }
    ];
    filters = _.unionBy(optionFilter, initialFilters, 'name') as Omit<FacetFilter, 'type'>[];
  } else {
    filters = _.filter(
      initialFilters,
      (filter: Omit<FacetFilter, 'type'>) => _.get(filter, 'name') !== name
    ) as Omit<FacetFilter, 'type'>[];
  }
  return filters;
}

/**
 * filters is calculated by selected categories and category conditions
 * @param categories selected categories
 * @param mappedFilters an object with key is category and value is filter which is matched with category
 */
export function getFiltersByCategories(
  categories: string[],
  mappedFilters: Record<string, Omit<FacetFilter, 'type'>[]>
) {
  // combine filters based on selected categories
  const combinedFilters = categories.reduce(
    (filters: Omit<FacetFilter, 'type'>[], category: string) => {
      if (!filters.length) return mappedFilters[category];
      else {
        return _.union(mappedFilters[category], filters);
      }
    },
    []
  );

  // get all unique filters by name and operator
  // concatenate value of duplicates filters
  return _.reduce(
    combinedFilters,
    (filters: any, filter) => {
      const index = _.findIndex(
        filters,
        (fil: any) => fil.name === filter.name && fil.operator === filter.operator
      );
      if (index !== -1) {
        filters[index] = {
          ...filters[index],
          value: _.concat(filters[index].value, filter.value)
        };
      } else {
        filters.push(filter);
      }
      return filters;
    },
    []
  );
}

/**
 * calculate filters based on category filters and advanced filters
 * @param categoryFilter
 * @param advancedFilter
 */
export function prepareFilters(
  categoryFilters: Omit<FacetFilter, 'type'>[],
  advancedFilters: Omit<FacetFilter, 'type'>[] = []
) {
  return _.reduce(
    categoryFilters,
    (result: Omit<FacetFilter, 'type'>[], categoryFilter: Omit<FacetFilter, 'type'>) => {
      if (!result.length) return [categoryFilter];

      // find the intersection between 2 filters
      const duplicatedFilter = _.find(
        result,
        filter => filter.name === categoryFilter.name && filter.operator === categoryFilter.operator
      );

      // push the filter to result if there is no intersection
      if (!duplicatedFilter) return [...result, categoryFilter];

      // else concatenate the value of filter that exists in both type of filters
      return [
        ..._.filter(result, filter => filter.name !== categoryFilter.name),
        {
          ...categoryFilter,
          value: _.uniq(_.flatten([categoryFilter.value, duplicatedFilter.value]))
        }
      ];
    },
    advancedFilters
  );
}
