import { createSlice, PayloadAction, createEntityAdapter } from '@reduxjs/toolkit';
import { Event, MediaResource, SearchResponse, SearchRequest } from 'services/Api';
import { Error } from 'services/Error';
import {
  RequestState,
  generateRequestState,
  setRequestError,
  startRequest
} from 'utils/reducerHelpers';
import _ from 'lodash';

export type Resource = MediaResource & {
  event: Event;
};

const mediaResourceAdapter = createEntityAdapter<Resource>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: (mediaResource: Resource) => mediaResource.id
});

const mediaResourceSelectors = mediaResourceAdapter.getSelectors<any>(
  state => state.resource.mediaResources.items
);

export const selectMediaResources = mediaResourceSelectors.selectAll;
export const selectMediaResourceById = mediaResourceSelectors.selectById;
export const selectMediaResourceCount = (state: any) => state.resource.mediaResources.itemCount;
export const selectListRequestState = (state: any) => state.resource.request.list;

type ResourceState = RequestState & {
  mediaResources: {
    items: ReturnType<typeof mediaResourceAdapter.getInitialState>;
    itemCount: number;
    totalCount: number;
    searchParams: SearchRequest;
  };
};

const initialState: ResourceState = {
  request: generateRequestState(['list', 'get'], ['list']),
  mediaResources: {
    items: mediaResourceAdapter.getInitialState(),
    itemCount: 0,
    totalCount: 0,
    searchParams: {
      facets: ['*'],
      filters: []
    }
  }
};

const resourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {
    listMediaResources(state, action: PayloadAction<any>) {
      const refresh = _.get(action.payload, 'refresh');
      if (refresh) {
        state.request.list.refreshing = true;
      } else {
        state.request.list.loading = true;
      }
      state.request.list.error = undefined;
    },
    searchMediaResources(state, action: PayloadAction<SearchRequest>) {
      startRequest(state.request.list);
      state.mediaResources.searchParams = action.payload;
    },
    searchMediaResourcesError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.list, action.payload);
    },
    mediaResources(state, action: PayloadAction<SearchResponse>) {
      const { items, itemCount, totalCount } = action.payload;
      mediaResourceAdapter.setAll(state.mediaResources.items, items as Resource[]);
      state.request.list.loading = false;
      state.request.list.refreshing = false;
      state.mediaResources.itemCount = itemCount || 0;
      state.mediaResources.totalCount = totalCount || 0;
    },
    clearMediaResources(state) {
      mediaResourceAdapter.removeAll(state.mediaResources.items);
      state.mediaResources.itemCount = 0;
      state.mediaResources.totalCount = 0;
    },
    getMediaResource(state, action) {
      startRequest(state.request.get);
    },
    getMediaResourceError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.get, action.payload);
    },
    mediaResourceRetrieved(state, action: PayloadAction<Resource>) {
      mediaResourceAdapter.addOne(state.mediaResources.items, action.payload);
      state.request.get.loading = false;
    }
  }
});

export const {
  listMediaResources,
  searchMediaResources,
  searchMediaResourcesError,
  mediaResources,
  clearMediaResources,
  getMediaResource,
  getMediaResourceError,
  mediaResourceRetrieved
} = resourceSlice.actions;
export type { MediaResource } from 'services/Api';
export default resourceSlice.reducer;
