import _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import Api, { ListValue } from 'services/Api';
import { handleError } from 'services/Error';
import {
  searchListValues as searchListValuesAction,
  searchListValuesError as searchListValuesErrorAction,
  listValues as listValuesAction,
  searchListValuesLoadMore as searchListValuesLoadMoreAction,
  moreLoadedListValues as moreLoadedListValuesAction,
  createOrUpdateListValue as createOrUpdateListValueAction,
  createOrUpdateListValueError as createOrUpdateListValueErrorAction,
  listValueUpserted as listValueUpsertedAction,
  deleteListValue as deleteListValueAction,
  deleteListValueError as deleteListValueErrorAction,
  listValueDeleted as listValueDeletedAction,
  selectAddedListValues,
  selectListValueCount,
  selectListValueSearchParams,
  SearchListValuesPayload
} from 'redux/reducers/listValue';
import { addOrUpdateListValueInProfile, deleteListValueFromProfile } from 'redux/reducers/profiles';
import { getRequestParams } from './appSagas';

function* searchListValues(action: SearchListValuesPayload) {
  try {
    const profileId = _.get(action.payload, 'profileId');
    const params = _.omit(action.payload, ['profileId', 'refresh']);
    const requestParams = yield call(getRequestParams);
    const listValues = yield call(Api.listValues.searchListValues, params, requestParams);
    yield put(listValuesAction(profileId, listValues));
  } catch (exception) {
    console.warn(exception);
    yield put(searchListValuesErrorAction(handleError(exception)));
  }
}

function* searchListValuesLoadMore(action: PayloadAction<{ count: number; profileId: string }>) {
  try {
    const { count, profileId } = action.payload;
    const listValueCount = yield select(state => selectListValueCount(state, profileId));
    const addedListValues = yield select(state => selectAddedListValues(state, profileId));
    const searchParams = yield select(state => selectListValueSearchParams(state, profileId));
    const params = {
      ...searchParams,
      offset: (searchParams.offset || 0) + (listValueCount - addedListValues.length),
      length: count,
      filters: searchParams.filters || []
    };
    const requestParams = yield call(getRequestParams);
    const listValues = yield call(Api.listValues.searchListValues, params, requestParams);
    yield put(moreLoadedListValuesAction(profileId, listValues));
  } catch (exception) {
    console.warn(exception);
    yield put(searchListValuesErrorAction(handleError(exception)));
  }
}

function* createOrUpdateListValue(action: PayloadAction<ListValue>) {
  try {
    const { id, ...data } = action.payload;
    const requestParams = yield call(getRequestParams);
    const listValue = yield call(Api.listValues.createOrUpdateListValue, id, data, requestParams);
    yield put(listValueUpsertedAction(listValue));
    yield put(addOrUpdateListValueInProfile(listValue, action.payload.profileId));
  } catch (exception) {
    console.warn(exception);
    yield put(createOrUpdateListValueErrorAction(handleError(exception)));
  }
}

function* deleteListValue(action: PayloadAction<{ id: string; profileId: string }>) {
  try {
    const { id, profileId } = action.payload;
    const requestParams = yield call(getRequestParams);
    yield call(Api.listValues.deleteListValue, id, requestParams);
    yield put(listValueDeletedAction({ id, profileId }));
    yield put(deleteListValueFromProfile(id, profileId));
  } catch (exception) {
    console.warn(exception);
    yield put(deleteListValueErrorAction(handleError(exception)));
  }
}

const sagas = [
  takeLatest(searchListValuesAction.type, searchListValues),
  takeLatest(searchListValuesLoadMoreAction.type, searchListValuesLoadMore),
  takeLatest(createOrUpdateListValueAction.type, createOrUpdateListValue),
  takeLatest(deleteListValueAction.type, deleteListValue)
];

export default sagas;
