import { call, put, takeLatest } from 'redux-saga/effects';
import Api from 'services/Api';
import {
  listStorages as listStoragesAction,
  listStoragesError as listStoragesErrorAction,
  storages as storagesAction
} from 'redux/reducers/storage';
import { getRequestParams } from './appSagas';

export function* listStorages() {
  try {
    const requestParams = yield call(getRequestParams);
    const storages = yield call(Api.storages.getStorages, {}, requestParams);
    yield put(storagesAction(storages));
  } catch (exception) {
    console.warn(exception);
    yield put(listStoragesErrorAction(exception));
  }
}

const sagas = [takeLatest(listStoragesAction.type, listStorages)];

export default sagas;
