import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  fieldDefinitions: [
    {
      updateDate: '2020-11-05T15:30:33.180Z',
      creationDate: '2020-11-05T15:30:33.180Z',
      id: 'score',
      name: 'Score',
      ownerId: 'unknown',
      type: 'number'
    },
    {
      updateDate: '2020-11-05T16:22:50.742Z',
      creationDate: '2020-11-05T16:22:50.742Z',
      link: {
        format: 'image'
      },
      id: 'clubLogo',
      name: 'Club Logo',
      ownerId: 'unknown',
      type: 'link'
    },
    {
      updateDate: '2020-11-05T15:30:14.033Z',
      validationErrorMessage: 'max 50 char',
      creationDate: '2020-11-05T15:30:14.033Z',
      id: 'position',
      name: 'Position',
      validationExpression: '^(?=.{1,50}$).*',
      ownerId: 'unknown',
      type: 'string'
    },
    {
      updateDate: '2020-11-05T16:22:25.087Z',
      creationDate: '2020-11-05T16:22:25.087Z',
      id: 'lfpClubId',
      name: 'LFP Club id',
      ownerId: 'unknown',
      type: 'string'
    },
    {
      updateDate: '2020-11-05T15:22:18.996Z',
      validationErrorMessage: 'max 50 char',
      creationDate: '2020-11-05T15:22:18.996Z',
      id: 'firstName',
      name: 'First name',
      validationExpression: '^(?=.{1,50}$).*',
      ownerId: 'unknown',
      type: 'string'
    },
    {
      updateDate: '2020-11-05T15:31:00.901Z',
      creationDate: '2020-11-05T15:31:00.901Z',
      id: 'homeTeam',
      name: 'Home team',
      ownerId: 'unknown',
      type: 'list',
      list: {
        profileId: 'lfpClub'
      }
    },
    {
      updateDate: '2020-11-05T16:21:56.468Z',
      creationDate: '2020-11-05T16:21:56.468Z',
      id: 'lfpShortName',
      name: 'LFP ShortName',
      ownerId: 'unknown',
      type: 'string'
    },
    {
      updateDate: '2020-11-05T15:29:40.991Z',
      validationErrorMessage: 'max 50 char',
      creationDate: '2020-11-05T15:29:40.991Z',
      id: 'familyName',
      name: 'Family name',
      validationExpression: '^(?=.{1,50}$).*',
      ownerId: 'unknown',
      type: 'string'
    },
    {
      updateDate: '2020-11-05T15:04:34.832Z',
      acceptSeveralValues: true,
      creationDate: '2020-11-05T15:04:34.832Z',
      id: 'lfpClub',
      name: 'Club',
      ownerId: 'unknown',
      type: 'list',
      list: {
        profileId: 'lfpClubProfile'
      }
    }
  ]
};

const fieldDefinitionSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    fieldDefinitions(state, action: PayloadAction<any>) {
      state.fieldDefinitions = action.payload;
    }
  }
});

export const { fieldDefinitions } = fieldDefinitionSlice.actions;
export type { FieldResponse } from 'services/Api';
export default fieldDefinitionSlice.reducer;
