import loadTheme from './utils/loadTheme';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import locales from 'assets/locales';
import React from 'react';
import ReactDOM from 'react-dom';

import '@fortawesome/fontawesome-pro/css/all.min.css';
import 'tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css';

import * as serviceWorker from './serviceWorker';
import configureStore from './redux/store';
import { Provider } from 'react-redux';

import i18n from 'i18next';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import ReactGA from 'react-ga';

import log from 'loglevel';
import { getDefaultLanguage } from './config/languages';

declare global {
  const zE: any;
}

async function init() {
  try {
    // Await the theme loading
    await loadTheme();

    // After the theme has loaded, dynamically import the App component
    const { default: App } = await import('./App');
    process.env.NODE_ENV === 'development' ? log.enableAll() : log.setLevel(log.levels.WARN);

    library.add(fas);
    library.add(far);
    library.add(fal);
    library.add(fab);

    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .use(backend)
      .init({
        debug: process.env.NODE_ENV === 'development' ? true : false,
        resources: locales,
        lng: getDefaultLanguage(),
        fallbackLng: getDefaultLanguage(),
        ns: ['app'],
        defaultNS: 'app',
        saveMissing:
          process.env.NODE_ENV === 'development' &&
          process.env.REACT_APP_MISSING_TRANSLATION === 'true'
            ? true
            : false,
        backend: {
          addPath: '/locales/add/{{ns}}/{{lng}}'
        },
        interpolation: {
          escapeValue: false
        },
        parseMissingKeyHandler(key) {
          return key.split('.').pop();
        }
      });

    const store = configureStore();

    if (process.env.NODE_ENV !== 'development') {
      Sentry.init({
        dsn: 'https://1539cf28da934cd0888417ecb3ff6a96@o448949.ingest.sentry.io/5439985',
        environment: process.env.REACT_APP_ENV_NAME,
        release: `webapp@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_NUMBER}`,
        integrations: [new Integrations.BrowserTracing()],

        // TODO: We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0
      });
    }

    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE || '');

    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>,
      document.getElementById('root')
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
    // Then render the App
  } catch (error) {
    console.error('Failed to load the theme or the app.', error);
  }
}

init();
