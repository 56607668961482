import { isLocalhost } from './devTools';

export type OrganizationType = 'lfp' | 'concacaf';
export const getOrganizationId = (): OrganizationType => {
  if (isLocalhost && process.env.NODE_ENV === 'development') {
    return (process.env.REACT_APP_LOCAL_ORG_ID ||
      'missing_env_REACT_APP_LOCAL_ORG_ID') as OrganizationType;
  }
  return window.location.hostname.split('.')[0] as OrganizationType;
};

export const organizationId = getOrganizationId();

export const apiBaseUrl = isLocalhost
  ? `https://${organizationId}.${process.env.REACT_APP_DOMAIN}/v1`
  : `/v1`;
//Remove the organization id from the domain to get the websocket domain
const domain = window.location.hostname.split('.').slice(1).join('.');
export const webSocketBaseUrl = `wss://ws.${domain}/v1`;

export const imageBaseUrl = `/statics/app`;
