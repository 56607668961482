import _ from 'lodash';

export type Error = { code: string; message?: any } | undefined;

export const handleError = (error: any) => {
  switch (error.name) {
    case 'TypeError':
      if (error.message === 'Failed to fetch') {
        return { code: 'NetworkError' };
      } else {
        return { code: 'unknown_error' };
      }
    // Amplify error
    case 'UserNotFoundException':
    case 'NotAuthorizedException':
    case 'InvalidPasswordException':
    case 'InvalidParameterException':
    case 'LimitExceededException':
    case 'CodeMismatchException':
      return error;
    default:
      return _.merge(
        { code: 'unknown_error' },
        { code: _.get(error, 'code'), message: _.get(error, 'message') }
      );
  }
};

export const handleMemberApiError = (error: any) => {
  console.warn(error);
  switch (error.code) {
    case 'NON_UNIQ_INPUT':
      return { code: error.code };
    default:
      return handleError(error);
  }
};
