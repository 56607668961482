import _ from 'lodash';
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role, RoleData, SearchRequest, SearchResponse } from 'services/Api';
import { Error } from 'services/Error';
import { RootState } from 'redux/reducers';
import {
  generateRequestState,
  generateSearchState,
  startRequest,
  setRequestError,
  RequestState,
  SearchState
} from 'utils/reducerHelpers';

const roleAdapter = createEntityAdapter<Role>({
  selectId: (role: Role) => role.id
});

const loadedRoleSelector = roleAdapter.getSelectors<RootState>(
  state => state.role.roles.loadedItems
);
const addedRoleSelector = roleAdapter.getSelectors<RootState>(state => state.role.roles.addedItems);

export const selectRoles = loadedRoleSelector.selectAll;
export const selectRoleById = loadedRoleSelector.selectById;
export const selectRoleCount = (state: RootState) => state.role.roles.itemCount;
export const selectRoleTotalCount = (state: RootState) => state.role.roles.totalCount;
export const selectRoleFacets = (state: RootState) => state.role.roles.facets;
export const selectRoleSearchParams = (state: RootState) => state.role.roles.searchParams;
export const selectRoleSavedSearch = (state: RootState) => state.role.roles.savedSearch;

export const selectAddedRoleCount = addedRoleSelector.selectTotal;

export const selectListRolesRequestState = (state: RootState) => state.role.request.list;
export const selectCreateRoleRequestState = (state: RootState) => state.role.request.create;
export const selectUpdateRoleRequestState = (state: RootState) => state.role.request.update;
export const selectDeleteRoleRequestState = (state: RootState) => state.role.request.delete;

export type RoleReducerState = RequestState & {
  roles: SearchState<Role>;
};

const initialState: RoleReducerState = {
  request: generateRequestState(['list', 'create', 'update', 'delete'], ['list']),
  roles: generateSearchState(roleAdapter)
};

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    searchRoles(state, action: PayloadAction<SearchRequest>) {
      const refresh = _.get(action.payload, 'refresh');
      const searchParams = _.omit(action.payload, ['refresh']);
      if (refresh) state.request.list.refreshing = true;
      else {
        state.request.list.loading = true;
        state.roles.searchParams = searchParams;
      }
      state.request.list.error = undefined;
    },
    searchRolesError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.list, action.payload);
    },
    rolesSearched(state, action: PayloadAction<SearchResponse>) {
      const { facets, items, itemCount, totalCount } = action.payload;
      roleAdapter.setAll(state.roles.loadedItems, items as Role[]);
      state.roles.facets = facets || {};
      state.roles.itemCount = itemCount || 0;
      state.roles.totalCount = totalCount;
      state.request.list.loading = false;
      state.request.list.refreshing = false;
    },
    searchRolesLoadMore(state, action: PayloadAction<number>) {
      startRequest(state.request.list);
    },
    moreLoadedRoles(state, action) {
      const { facets, items, itemCount } = action.payload;
      roleAdapter.addMany(state.roles.loadedItems, items as Role[]);
      state.roles.facets = facets!;
      state.roles.itemCount += itemCount || 0;
      state.roles.itemCount = Math.min(state.roles.itemCount, state.roles.loadedItems.ids.length);
      state.request.list.loading = false;
    },
    createRole(state, action: PayloadAction<RoleData>) {
      startRequest(state.request.create);
    },
    createRoleError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.create, action.payload);
    },
    roleCreated(state, action: PayloadAction<Role>) {
      roleAdapter.addOne(state.roles.loadedItems, action.payload);
      roleAdapter.addOne(state.roles.addedItems, action.payload);
      state.roles.itemCount += 1;
      state.roles.totalCount += 1;
      state.request.create.loading = false;
    },
    updateRole(state, action: PayloadAction<RoleData & { id: string }>) {
      startRequest(state.request.update);
    },
    updateRoleError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.update, action.payload);
    },
    roleUpdated(state, action: PayloadAction<Role>) {
      const { id, ...data } = action.payload;
      roleAdapter.updateOne(state.roles.loadedItems, { id, changes: data });
      state.request.update.loading = false;
    },
    deleteRole(state, action: PayloadAction<string>) {
      startRequest(state.request.delete);
    },
    deleteRoleError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.delete, action.payload);
    },
    roleDeleted(state, action: PayloadAction<string>) {
      roleAdapter.removeOne(state.roles.loadedItems, action.payload);
      roleAdapter.removeOne(state.roles.addedItems, action.payload);
      state.roles.itemCount -= 1;
      state.roles.totalCount -= 1;
      state.request.delete.loading = false;
    },
    updateSavedSearch(state, action) {
      state.roles.savedSearch = action.payload;
    }
  }
});

export const {
  searchRoles,
  searchRolesError,
  rolesSearched,
  searchRolesLoadMore,
  moreLoadedRoles,
  createRole,
  createRoleError,
  roleCreated,
  updateRole,
  updateRoleError,
  roleUpdated,
  deleteRole,
  deleteRoleError,
  roleDeleted,
  updateSavedSearch
} = roleSlice.actions;
export type { Role };
export default roleSlice.reducer;
