import moment from 'moment';
import { all, call, put, select, takeLatest, takeEvery, CallEffect } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import Api, { SearchRequest } from 'services/Api';
import {
  listEvents as listEventsAction,
  listEventsLoadMore as listEventsLoadMoreAction,
  listEventsError as listEventsErrorAction,
  events as eventsAction,
  moreLoadedEvents as moreLoadedEventsAction,
  addEvent as addEventAction,
  addEventError as addEventErrorAction,
  eventAdded as eventAddedAction,
  getMediaResourcesById as getMediaResourcesByIdAction,
  getMediaResourcesByEventId as getMediaResourcesByEventIdAction,
  getMediaResourcesByEventIdError as getMediaResourcesByEventIdErrorAction,
  mediaResourcesByEventId as mediaResourcesByEventIdAction,
  getClipFormatByMediaResourceId as getClipFormatByMediaResourceIdAction,
  getClipFormatByMediaResourceIdError as getClipFormatByMediaResourceIdErrorAction,
  searchMediaResourcesForListEvents as searchMediaResourcesForListEventsAction,
  mediaResourcesForListEvents as mediaResourcesForListEventsAction,
  getMediaResourcesForListEventsError as getMediaResourcesForListEventsErrorAction,
  clipFormatByMediaResource as clipFormatByMediaResourceAction,
  getEvent as getEventAction,
  getEventError as getEventErrorAction,
  eventRetrieved as eventRetrievedAction,
  updateEvent as updateEventAction,
  updateEventError as updateEventErrorAction,
  eventUpdated as eventUpdatedAction,
  deleteEvent as deleteEventAction,
  deleteEventError as deleteEventErrorAction,
  eventDeleted as eventDeletedAction,
  logs as logsAction,
  listLogsByEventId as listLogsByEventIdAction,
  listLogsByEventIdError as listLogsByEventIdErrorAction,
  listLogsByEventIdLoadMore as listLogsByEventIdLoadMoreAction,
  moreLoadedLogs as moreLoadedLogsAction,
  addLog as addLogAction,
  addLogError as addLogErrorAction,
  logAdded as logAddedAction,
  updateLog as updateLogAction,
  updateLogError as updateLogErrorAction,
  logUpdated as logUpdatedAction,
  deleteLog as deleteLogAction,
  deleteLogError as deleteLogErrorAction,
  logDeleted as logDeletedAction,
  downloadLogs as downloadLogsAction,
  downloadLogsError as downloadLogsErrorAction,
  logsDownloaded as logsDownloadedAction,
  searchEvents as searchEventsAction,
  searchEventsLoadmore as searchEventsLoadmoreAction,
  moreLoadedSearchEvents as moreLoadedSearchEventsAction,
  searchEventsSuccess as searchEventsSuccessAction,
  searchEventsFail as searchEventsFailAction,
  updateMediaResource as updateMediaResourceAction,
  updateMediaResourceError as updateMediaResourceErrorAction,
  mediaResourceUpdated as mediaResourceUpdatedAction,
  updateMediaResourceLive as updateMediaResourceLiveAction,
  updateMediaResourceLiveError as updateMediaResourceLiveErrorAction,
  mediaResourceLiveUpdated as mediaResourceLiveUpdatedAction,
  deleteMediaResource as deleteMediaResourceAction,
  deleteMediaResourceError as deleteMediaResourceErrorAction,
  mediaResourceDeleted as mediaResourceDeletedAction,
  selectSearchEventsQuery,
  selectSearchEventsResult,
  selectEventSearchParams,
  selectEventCount,
  selectLogsSearchParams,
  selectLogsByEventIdCountRaw
} from 'redux/reducers/event';
import { getRequestParams } from './appSagas';
import _ from 'lodash';
import { handleError } from 'services/Error';
import log from 'loglevel';
import { FacetSetting } from 'tools/setttings';
import { useSelector } from 'react-redux';

export function* listEvents(action: any) {
  try {
    let params: any = {
      length: 20
    };
    if (action && action.payload) {
      params = {
        ..._.omit(action.payload, 'refresh'),
        length: action.payload.length || 20,
        offset: action.payload.offset || 0,
        filters: action.payload.filters || [],
        facets: action.payload.facets || ['*']
      };
    }
    const requestParams = yield call(getRequestParams);
    log.debug('listEvents', params);
    const events = yield call(Api.events.searchEvents, params, requestParams);
    yield put(eventsAction(events));
  } catch (exception) {
    console.warn(exception);
    yield put(listEventsErrorAction(handleError(exception)));
  }
}

export function* listEventsLoadMore(action: any) {
  try {
    const count = action.payload || 20;
    const eventCount = yield select(selectEventCount);
    const searchParams = yield select(selectEventSearchParams);
    const params = {
      ...searchParams,
      length: count,
      offset: searchParams.offset !== undefined ? searchParams.offset + eventCount : eventCount,
      filters: searchParams.filters || []
    };
    const requestParams = yield call(getRequestParams);
    log.debug('listEventsLoadMore', params);
    const events = yield call(Api.events.searchEvents, params, requestParams);
    yield put(moreLoadedEventsAction(events));
  } catch (exception) {
    console.warn(exception);
    yield put(listEventsErrorAction(handleError(exception)));
  }
}

export function* getEventById(action: any) {
  try {
    const eventId = action.payload;
    const requestParams = yield call(getRequestParams);
    log.debug('getEventById', eventId);
    const event = yield call(Api.events.getEvent, eventId, requestParams);
    yield put(eventRetrievedAction(event));
  } catch (exception) {
    console.warn(exception);
    yield put(getEventErrorAction(handleError(exception)));
  }
}

export function* searchMediaResourcesForListEvents(action: any) {
  try {
    const { listEvents } = action.payload;
    const data: SearchRequest = {
      filters:
        listEvents && listEvents.length > 0
          ? [
              {
                name: 'eventId',
                operator: '=',
                value: listEvents
              }
            ]
          : ([] as any)
    };
    const requestParams = yield call(getRequestParams);
    const mediaResources = yield call(Api.resources.searchResources, data, {}, requestParams);
    yield put(mediaResourcesForListEventsAction(listEvents, mediaResources));
  } catch (exception) {
    yield put(getMediaResourcesForListEventsErrorAction(handleError(exception)));
  }
}

export function* getMediaResourcesByEventId(action: any) {
  try {
    const { eventId, options } = action.payload;
    const params = { eventId, ..._.omit(options, 'refresh') };
    const requestParams = yield call(getRequestParams);
    const mediaResources = yield call(Api.resources.getResources, params, requestParams);

    // we need to keep durationInMilliseconds field as full for admin user requesting with fullLive Param
    if (
      options?.fullLive &&
      mediaResources[0]?.live &&
      mediaResources[0]?.live?.startedAt &&
      mediaResources[0]?.live?.stoppedAt
    ) {
      mediaResources[0].durationInMilliseconds = moment(mediaResources[0]?.live?.stoppedAt).diff(
        moment(mediaResources[0]?.live?.startedAt)
      );
    }

    yield put(mediaResourcesByEventIdAction(eventId, mediaResources));
  } catch (exception) {
    console.warn(exception);
    yield put(getMediaResourcesByEventIdErrorAction(handleError(exception)));
  }
}

export function* getMediaResourceById(action: any) {
  try {
    const resourceId = action.payload;
    const requestParams = yield call(getRequestParams);
    const mediaResource = yield call(Api.resources.getResource, resourceId, requestParams);
    yield put(mediaResourcesByEventIdAction(mediaResource.eventId, [mediaResource]));
  } catch (exception) {
    console.warn(exception);
    yield put(getEventErrorAction(handleError(exception)));
  }
}

export function* getClipFormatByMediaResourceId(action: PayloadAction<string | string[]>) {
  try {
    const requestParams = yield call(getRequestParams);
    if (!Array.isArray(action.payload)) {
      const resourceId = action.payload;
      const clipFormats = yield call(
        Api.resources.getOuptutFormatsForResource,
        resourceId,
        requestParams
      );
      yield put(clipFormatByMediaResourceAction(resourceId, clipFormats));
    } else {
      const resourceIds = action.payload;
      const clipFormatsByResourceIds = yield all(
        resourceIds.reduce((effects: Record<string, CallEffect>, resourceId) => {
          effects[resourceId] = call(
            Api.resources.getOuptutFormatsForResource,
            resourceId,
            requestParams
          );
          return effects;
        }, {})
      );
      yield all(
        _.map(clipFormatsByResourceIds, (clipFormats, resourceId) =>
          put(clipFormatByMediaResourceAction(resourceId, clipFormats))
        )
      );
    }
  } catch (exception) {
    console.warn(exception);
    yield put(getClipFormatByMediaResourceIdErrorAction(handleError(exception)));
  }
}

export function* logsByEventId(action: any) {
  try {
    const { eventId, ...otherParams } = action.payload;
    const data: SearchRequest = {
      ..._.omit(otherParams, 'refresh'),
      facets: ['*'],
      filters: _.unionBy(
        action.payload.filters || [],
        [
          {
            name: 'eventId',
            operator: '=',
            value: [eventId]
          }
        ],
        'name'
      ) as any,
      length: action.payload.length || 100,
      offset: action.payload.offset || 0
    };
    const requestParams = yield call(getRequestParams);
    const logs = yield call(Api.logs.searchLogs, data, requestParams);
    yield put(logsAction(logs, eventId));
  } catch (exception) {
    console.warn(exception);
    yield put(listLogsByEventIdErrorAction(handleError(exception)));
  }
}

export function* logsByEventIdLoadMore(action: any) {
  try {
    const count = action.payload || 100;
    const searchParams = yield select(selectLogsSearchParams);
    const eventId = searchParams.filters.find((filter: any) => filter.name === 'eventId').value;
    const itemCountRaw = yield select(state => selectLogsByEventIdCountRaw(state, eventId));
    const params = {
      ...searchParams,
      length: searchParams.length !== undefined ? searchParams.length : count,
      offset: searchParams.offset !== undefined ? searchParams.offset + itemCountRaw : itemCountRaw
    };
    const requestParams = yield call(getRequestParams);
    const results = yield call(Api.logs.searchLogs, params, requestParams);
    yield put(moreLoadedLogsAction(eventId, results));
  } catch (exception) {
    console.warn(exception);
    yield put(listLogsByEventIdErrorAction(handleError(exception)));
  }
}

export function* addLog(action: any) {
  try {
    const data = action.payload;
    const requestParams = yield call(getRequestParams);
    const log = yield call(Api.logs.createLog, data, requestParams);
    yield put(logAddedAction(log));
  } catch (exception) {
    console.warn(exception);
    yield put(addLogErrorAction(handleError(exception)));
  }
}

export function* updateLog(action: any) {
  try {
    const { id, ...data } = action.payload;
    const requestParams = yield call(getRequestParams);
    const event = yield call(Api.logs.updateLog, id, data, requestParams);
    yield put(logUpdatedAction(event));
  } catch (exception) {
    console.warn(exception);
    yield put(updateLogErrorAction(handleError(exception)));
  }
}

export function* deleteLog(action: any) {
  try {
    const { id: logId, eventId } = action.payload;
    const requestParams = yield call(getRequestParams);
    yield call(Api.logs.deleteLog, logId, requestParams);
    yield put(logDeletedAction(eventId, logId));
  } catch (exception) {
    console.warn(exception);
    yield put(deleteLogErrorAction(handleError(exception)));
  }
}

export function* downloadLogs(action: PayloadAction<string>) {
  try {
    const eventId = action.payload;
    const requestParams = yield call(getRequestParams);
    const logFile = yield call(Api.events.downloadEventLogs, eventId, requestParams);
    yield put(logsDownloadedAction(eventId, logFile));
  } catch (exception) {
    console.warn(exception);
    yield put(downloadLogsErrorAction(handleError(exception)));
  }
}

export function* searchEvents(action: any, searchSettings: { facets: FacetSetting[] }) {
  const facets: FacetSetting[] = yield select(
    state => state.auth.currentUser.settings['uiconfigs.search'].facets
  );
  try {
    const params = {
      facets: facets.map(config => config.facet),
      ...action.payload,
      length: action.payload.length || 50,
      offset: action.payload.offset || 0
    };
    const requestParams = yield call(getRequestParams);
    const results = yield call(Api.search.search, params, requestParams);
    yield put(searchEventsSuccessAction(results));
  } catch (exception) {
    console.warn(exception);
    yield put(searchEventsFailAction(handleError(exception)));
  }
}

function* searchEventsLoadmore(action: any) {
  try {
    const count = action.payload || 50;
    const lastSearchQuery = yield select(selectSearchEventsQuery);
    const { items: lastSearchEvents } = yield select(selectSearchEventsResult);
    const params = {
      ...lastSearchQuery.current,
      length: lastSearchQuery.current.length !== undefined ? lastSearchQuery.current.length : count,
      offset:
        lastSearchQuery.current.offset !== undefined
          ? lastSearchQuery.current.offset + lastSearchEvents.length
          : lastSearchEvents.length
    };
    const requestParams = yield call(getRequestParams);
    const results = yield call(Api.search.search, params, requestParams);
    yield put(moreLoadedSearchEventsAction(results));
  } catch (exception) {
    console.warn(exception);
    yield put(searchEventsFailAction(handleError(exception)));
  }
}
export function* addEvent(action: any) {
  try {
    const requestParams = yield call(getRequestParams);
    const event = yield call(Api.events.createEvent, action.payload, requestParams);
    yield put(eventAddedAction(event));
  } catch (exception) {
    console.warn(exception);
    yield put(addEventErrorAction(handleError(exception)));
  }
}

export function* updateEvent(action: any) {
  try {
    const { id, ...data } = action.payload;
    const requestParams = yield call(getRequestParams);
    const event = yield call(Api.events.updateEvent, id, data, requestParams);
    yield put(eventUpdatedAction(event));
  } catch (exception) {
    console.warn(exception);
    yield put(updateEventErrorAction(handleError(exception)));
  }
}

export function* deleteEvent(action: any) {
  try {
    const { id: eventId } = action.payload;
    const requestParams = yield call(getRequestParams);
    yield call(Api.events.deleteEvent, eventId, requestParams);
    yield put(eventDeletedAction(eventId));
  } catch (exception) {
    console.warn(exception);
    yield put(deleteEventErrorAction(handleError(exception)));
  }
}

export function* updateMediaResource(action: any) {
  try {
    const { id, ...data } = action.payload;
    const requestParams = yield call(getRequestParams);
    const resource = yield call(Api.resources.updateResource, id, data, requestParams);
    yield put(mediaResourceUpdatedAction(resource.eventId, resource));
  } catch (exception) {
    console.warn(exception);
    yield put(updateMediaResourceErrorAction(handleError(exception)));
  }
}

export function* updateMediaResourceLive(action: any) {
  try {
    const { id, ...data } = action.payload;
    const requestParams = yield call(getRequestParams);
    const resource = yield call(Api.resources.updateMediaResourceLive, id, data, requestParams);

    // we need to keep durationInMilliseconds field as full for admin user
    if (resource?.live?.startedAt && resource?.live?.stoppedAt) {
      resource.durationInMilliseconds = moment(resource.live.stoppedAt).diff(
        moment(resource.live.startedAt)
      );
    }

    yield put(mediaResourceLiveUpdatedAction(resource.eventId, resource));
  } catch (exception) {
    console.warn(exception);
    yield put(updateMediaResourceLiveErrorAction(handleError(exception)));
  }
}

export function* deleteMediaResource(action: any) {
  try {
    const { id: resourceId, eventId } = action.payload;
    const requestParams = yield call(getRequestParams);
    yield call(Api.resources.deleteResource, resourceId, undefined, requestParams);
    yield put(mediaResourceDeletedAction(eventId, resourceId));
  } catch (exception) {
    console.warn(exception);
    yield put(deleteMediaResourceErrorAction(handleError(exception)));
  }
}

const sagas = [
  takeLatest(listEventsAction.type, listEvents),
  takeLatest(listEventsLoadMoreAction.type, listEventsLoadMore),
  takeLatest(listLogsByEventIdAction.type, logsByEventId),
  takeLatest(listLogsByEventIdLoadMoreAction.type, logsByEventIdLoadMore),
  takeLatest(addLogAction.type, addLog),
  takeLatest(updateLogAction.type, updateLog),
  takeLatest(deleteLogAction.type, deleteLog),
  takeLatest(getEventAction.type, getEventById),
  takeLatest(searchEventsAction.type, searchEvents),
  takeLatest(searchEventsLoadmoreAction.type, searchEventsLoadmore),
  takeEvery(getMediaResourcesByEventIdAction.type, getMediaResourcesByEventId),
  takeLatest(searchMediaResourcesForListEventsAction.type, searchMediaResourcesForListEvents),
  takeEvery(getMediaResourcesByIdAction.type, getMediaResourceById),
  takeLatest(getClipFormatByMediaResourceIdAction.type, getClipFormatByMediaResourceId),
  takeLatest(addEventAction.type, addEvent),
  takeLatest(updateEventAction.type, updateEvent),
  takeLatest(deleteEventAction.type, deleteEvent),
  takeLatest(updateMediaResourceAction.type, updateMediaResource),
  takeLatest(updateMediaResourceLiveAction.type, updateMediaResourceLive),
  takeLatest(deleteMediaResourceAction.type, deleteMediaResource),
  takeLatest(downloadLogsAction.type, downloadLogs)
];

export default sagas;
