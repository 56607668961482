import _ from 'lodash';
import { createSlice, PayloadAction, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { Profile, ListValue } from 'services/Api';
import {
  RequestState,
  generateRequestState,
  startRequest,
  setRequestError
} from 'utils/reducerHelpers';
import { Error } from 'services/Error';

const profileAdapter = createEntityAdapter<Profile>({
  selectId: (profile: Profile) => profile.id
});

const profileSelectors = profileAdapter.getSelectors<any>(state => state.profile.profiles);

export const selectProfiles = profileSelectors.selectAll;
export const selectProfileById = profileSelectors.selectById;
export const selectProfileByIds = createSelector(
  profileSelectors.selectEntities,
  (state: any, ids: string[]) => ids,
  (profileEntities, ids) => {
    return ids.reduce((profiles: Array<Profile>, id) => {
      const profile = profileEntities[id];
      if (profile) profiles.push(profile);
      return profiles;
    }, []);
  }
);

const listValueAdapter = createEntityAdapter<ListValue>({
  selectId: (listValue: ListValue) => listValue.id
});

const listValueSelectors = listValueAdapter.getSelectors<any>(state => state.profile.listValues);

export const selectListValues = listValueSelectors.selectAll;
// TODO: memoize value as the selector return a value that is not from the state
export const selectListValueByProfileId = (profileId: string, state: any): ListValue[] => {
  return state.profile.listValues[profileId]
    ? state.profile.listValues[profileId].ids.map(
        (id: string) => state.profile.listValues[profileId].entities[id]
      )
    : [];
};
// TODO: memoize value as the selector return a value that is not from the state
export const selectListValueById = (profileId: string, id: string, state: any) => {
  if (!state.profile.listValues[profileId]) return {};
  return state.profile.listValues[profileId].entities[id] || {};
};

type ProfileReducerState = RequestState & {
  profiles: ReturnType<typeof profileAdapter.getInitialState>;
  listValues: Record<string, ReturnType<typeof listValueAdapter.getInitialState>>;
  backgroundProfileAndListValueRefresh: boolean;
};

const initialState: ProfileReducerState = {
  request: generateRequestState(
    ['listProfiles', 'getProfile', 'listListValues'],
    ['listListValues']
  ),
  profiles: profileAdapter.getInitialState(),
  listValues: {},
  backgroundProfileAndListValueRefresh: false
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    listProfiles: {
      reducer(state, action: PayloadAction<object | undefined>) {
        startRequest(state.request.listProfiles);
      },
      prepare: (params?: object) => ({
        payload: {
          params
        }
      })
    },
    listProfilesError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.listProfiles, action.payload);
    },
    profiles(state, action: PayloadAction<any>) {
      profileAdapter.setAll(state.profiles, action.payload);
    },
    getProfileById: {
      reducer(state, action: PayloadAction<string>) {
        startRequest(state.request.getProfile);
      },
      prepare: (profileId: string) => ({
        payload: profileId
      })
    },
    getProfileByIdError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.getProfile, action.payload);
    },
    profile(state, action: PayloadAction<any>) {
      profileAdapter.addOne(state.profiles, action.payload);
    },
    listListValues: {
      reducer(state, action: PayloadAction<object | undefined>) {
        startRequest(state.request.listListValues);
      },
      prepare: (params?: any) => ({
        payload: {
          params
        }
      })
    },
    listListValuesError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.listListValues, action.payload);
    },
    listValues(state, action: PayloadAction<ListValue[]>) {
      const listValuesGroupedByProfileId = _.groupBy(action.payload, 'profileId');
      state.listValues = _.mapValues(listValuesGroupedByProfileId, listValues => ({
        ids: _.map(listValues, listValue => listValue.id),
        entities: _.keyBy(listValues, 'id')
      }));
      state.request.listListValues.loading = false;
      state.request.listListValues.refreshing = false;
    },
    listValuesByProfileId(state, action: PayloadAction<string>) {
      startRequest(state.request.listListValues);
    },
    listValuesByProfileIdError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.listListValues, action.payload);
    },
    listValue: {
      reducer(state, action: PayloadAction<any>) {
        const profileId = action.payload.profileId as string;
        const listValue = action.payload.listValue;
        const newState: any = (state.listValues = {
          ...state.listValues
        });
        if (!newState[profileId]) {
          newState[profileId] = listValueAdapter.getInitialState();
        }
        // TODO: typing
        newState[profileId] = {
          ids: listValue.map((item: any) => item.id),
          entities: listValue.reduce((entities: any, item: any) => {
            entities[item.id] = item;
            return entities;
          }, {})
        };
        state.listValues = newState;
        state.request.listListValues.loading = false;
        state.request.listListValues.refreshing = false;
      },
      prepare: (listValue: any, profileId) => ({
        payload: {
          listValue,
          profileId
        }
      })
    },
    addOrUpdateListValueInProfile: {
      reducer(state, action: PayloadAction<{ listValue: any; profileId: string }>) {
        const profileId = action.payload.profileId;
        const listValue = action.payload.listValue;
        const newState: any = (state.listValues = {
          ...state.listValues
        });
        if (!newState[profileId]) {
          newState[profileId] = listValueAdapter.getInitialState();
        }

        const existingListValueIndex = newState[profileId].ids.indexOf(listValue.id);

        if (existingListValueIndex === -1) {
          newState[profileId].ids.push(listValue.id);
          newState[profileId].entities[listValue.id] = listValue;
        } else {
          newState[profileId].entities[listValue.id] = listValue;
        }

        state.listValues = newState;
        state.request.listListValues.loading = false;
        state.request.listListValues.refreshing = false;
      },
      prepare: (listValue: any, profileId) => ({
        payload: {
          listValue,
          profileId
        }
      })
    },
    deleteListValueFromProfile: {
      reducer(state, action: PayloadAction<{ listValueId: string; profileId: string }>) {
        const profileId = action.payload.profileId;
        const listValueId = action.payload.listValueId;
        const newState: any = (state.listValues = {
          ...state.listValues
        });
        if (!newState[profileId]) {
          return;
        }

        const existingListValueIndex = newState[profileId].ids.indexOf(listValueId);

        if (existingListValueIndex === -1) {
          return;
        }

        newState[profileId].ids = newState[profileId].ids.filter(
          (id: string) => id !== listValueId
        );
        delete newState[profileId].entities[listValueId];

        state.listValues = newState;
        state.request.listListValues.loading = false;
        state.request.listListValues.refreshing = false;
      },
      prepare: (listValueId: string, profileId) => ({
        payload: {
          listValueId,
          profileId
        }
      })
    }
  }
});

export const {
  listProfiles,
  listProfilesError,
  profiles,
  getProfileById,
  getProfileByIdError,
  profile,
  listListValues,
  listListValuesError,
  listValues,
  listValuesByProfileId,
  listValuesByProfileIdError,
  listValue,
  addOrUpdateListValueInProfile,
  deleteListValueFromProfile
} = profileSlice.actions;
export type { ListValue, Profile } from 'services/Api';
export default profileSlice.reducer;
