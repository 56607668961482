import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import Api, { CreditTransactionData } from 'services/Api';
import { handleError } from 'services/Error';
import { currentUser as currentUserAction, selectAuthUser } from 'redux/reducers/auth';
import {
  createCreditTransaction as createCreditTransactionAction,
  createCreditTransactionError as createCreditTransactionErrorAction,
  creditTransactionCreated as creditTransactionCreatedAction
} from 'redux/reducers/creditTransaction';
import { memberUpdated as memberUpdatedAction, selectMemberById } from 'redux/reducers/member';
import { organizationId } from '../../tools/organizationTools';

export function* createCreditTransaction(action: PayloadAction<CreditTransactionData>) {
  try {
    const token = yield select(state => state.auth.accessToken);
    const data = action.payload;
    const creditTransaction = yield call(Api.creditTransactions.createCreditTransaction, data, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Organization-Id': organizationId
      }
    });
    const member = yield select(state => selectMemberById(state, creditTransaction.memberId));
    const currentUser = yield select(selectAuthUser);
    yield put(creditTransactionCreatedAction(creditTransaction));
    yield put(memberUpdatedAction({ ...member, credit: member.credit + creditTransaction.amount }));
    if (currentUser.memberId === creditTransaction.memberId) {
      yield put(
        currentUserAction({ ...currentUser, credit: currentUser.credit + creditTransaction.amount })
      );
    }
  } catch (exception) {
    console.warn(exception);
    yield put(createCreditTransactionErrorAction(handleError(exception)));
  }
}

const sagas = [takeLatest(createCreditTransactionAction.type, createCreditTransaction)];

export default sagas;
