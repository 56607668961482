import { all, call, put, takeLatest, delay, take, fork, cancel, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import Api, { MemberApprobation } from 'services/Api';
import {
  signIn,
  completeNewPassword,
  forgotPassword,
  forgotPasswordSubmit,
  signOut,
  AuthState,
  ChallengeName,
  currentSession,
  CognitoUserInterface,
  refeshToken
} from 'services/Auth';
import {
  startBackgroundCurrentAccessRefresh,
  stopBackgroundCurrentAccessRefresh,
  startBackgroundTokenRefresh,
  stopBackgroundTokenRefresh
} from 'redux/reducers/app';
import {
  accessToken,
  signIn as signInAction,
  completeNewPassword as completeNewPasswordAction,
  forgotPassword as forgotPasswordAction,
  forgotPasswordSubmit as forgotPasswordSubmitAction,
  signOut as signOutAction,
  impersonateUser as impersonateUserAction,
  impersonateUserError as impersonateUserErrorAction,
  userImpersonated as userImpersonatedAction,
  stopImpersonateUser as stopImpersonateUserAction,
  addApprobations as addApprobationsAction,
  addApprobationsError as addApprobationsErrorAction,
  approbationsAdded as approbationsAddedAction,
  state
} from 'redux/reducers/auth';
import {
  resetStoreSaga,
  currentAccessSaga,
  getRequestParams,
  startBackgroundTasks,
  stopBackgroundTasks
} from './appSagas';
import { handleError } from 'services/Error';
import log from 'loglevel';
import { LOCAL_STORAGE_KEYS } from 'constants/LocalStorageKeys';
import { organizationId } from '../../tools/organizationTools';

let userForRequireNewPassword: CognitoUserInterface;

// TODO: use correcct typing
function* signInSaga(action: any) {
  try {
    const result = yield call(signIn, {
      username: action.payload.email,
      password: action.payload.password
    });
    if (result.challengeName === ChallengeName.NewPasswordRequired) {
      userForRequireNewPassword = result;
      yield put(state(AuthState.ResetPassword));
    } else {
      const session = yield call(currentSession);
      yield put(accessToken(session.getAccessToken().getJwtToken()));
      // yield call(setApiSecurtityData, session)
      yield call(currentAccessSaga, action.payload.language);
      yield put(state(AuthState.SignedIn));
      yield call(startBackgroundTasks);
    }
  } catch (exception) {
    console.warn(exception);
    yield put(state(AuthState.SignIn, handleError(exception)));
  }
}

// TODO: use correcct typing
function* completeNewPasswordSaga(action: any) {
  try {
    yield call(completeNewPassword, {
      user: userForRequireNewPassword,
      password: action.payload.password
    });
    yield put(state('REQUIRE_NEW_PASSWORD_SUCCEEDED'));
  } catch (exception) {
    console.error(exception);
    yield put(state(AuthState.ResetPassword, handleError(exception)));
  }
}

// TODO: use correcct typing
function* forgotPasswordSaga(action: any) {
  try {
    yield call(forgotPassword, action.payload.email);
    yield put(state(AuthState.VerifyContact));
  } catch (exception) {
    console.error(exception);
    yield put(state(AuthState.ForgotPassword, handleError(exception)));
  }
}

// TODO: use correcct typing
function* forgotPasswordSubmitSaga(action: any) {
  try {
    yield call(
      forgotPasswordSubmit,
      action.payload.email,
      action.payload.code,
      action.payload.newPassword
    );
    yield put(state('RESET_PASSWORD_SUCCEEDED'));
  } catch (exception) {
    console.error(exception);
    yield put(state(AuthState.VerifyContact, handleError(exception)));
  }
}

// TODO: use correcct typing
function* signOutSaga(action: any) {
  try {
    yield call(signOut);
    yield call(stopBackgroundTasks);
    yield call(resetStoreSaga);
    yield put(state(AuthState.SignIn));
    localStorage.removeItem(LOCAL_STORAGE_KEYS.playerMuted);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.playerVolume);
  } catch (exception) {
    console.error(exception);
    yield put(state(AuthState.SignIn, handleError(exception)));
  }
}

function* refreshTokenBg() {
  log.debug('token auto refresh process started');
  try {
    while (true) {
      const session = yield call(currentSession);
      const expirationTimestamp = session.accessToken.payload.exp * 1000;
      const nowTimestamp = new Date().valueOf();
      const fiveMinutes = 60 * 1000;
      const duration = expirationTimestamp - nowTimestamp - fiveMinutes;
      log.debug('next refresh schedule', {
        expirationDate: new Date(expirationTimestamp),
        now: new Date(),
        renewalDate: new Date(nowTimestamp + duration)
      });
      yield delay(duration);
      const result = yield call(refeshToken);
      yield put(accessToken(result.getAccessToken().getJwtToken()));
      log.debug('token refreshed', new Date());
    }
  } catch (exception) {
    yield put(signOutAction());
  } finally {
    log.debug('token auto refresh canceled');
  }
}

export function* startRefreshTokenMonitor() {
  while (yield take(startBackgroundTokenRefresh.type)) {
    // starts the task in the background
    const bgRefreshToken = yield fork(refreshTokenBg);

    // wait for the user stop action
    yield take(stopBackgroundTokenRefresh.type);
    // user clicked stop. cancel the background task
    // this will cause the forked bgSync task to jump into its finally block
    yield cancel(bgRefreshToken);
  }
}

export function* takeIdentity(action: PayloadAction<string>) {
  try {
    const token = yield select(state => state.auth.accessToken);
    const userId = action.payload;
    const impersonatedUser = yield call(Api.members.getMyAccess, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Organization-Id': organizationId,
        'Impersonate-User-Id': userId
      }
    });
    yield put(userImpersonatedAction(impersonatedUser));
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.impersonatedUserId,
      JSON.stringify(impersonatedUser.userId)
    );
  } catch (exception) {
    console.warn(exception);
    yield put(impersonateUserErrorAction(handleError(exception)));
  }
}

export function stopTakeIdentity() {
  localStorage.setItem(LOCAL_STORAGE_KEYS.impersonatedUserId, JSON.stringify(null));
}

export function* addApprobations(action: PayloadAction<MemberApprobation[]>) {
  try {
    const approbations = action.payload;
    const requestParams = yield call(getRequestParams);
    const acceptedApprobations = yield all(
      approbations.map(data => call(Api.members.addMemberApprobation, data, requestParams))
    );
    yield put(approbationsAddedAction(acceptedApprobations));
  } catch (exception) {
    console.warn(exception);
    yield put(addApprobationsErrorAction(handleError(exception)));
  }
}

function* refreshCurrentAccessBg(timeToLive: number) {
  while (true) {
    yield delay(timeToLive * 1000);
    yield call(currentAccessSaga);
  }
}

export function* startRefreshCurrentAccessMonitor() {
  while (yield take(startBackgroundCurrentAccessRefresh.type)) {
    const bgRefreshCurrentAccess = yield fork(refreshCurrentAccessBg, 60 * 60);

    yield take(stopBackgroundCurrentAccessRefresh.type);
    yield cancel(bgRefreshCurrentAccess);
  }
}

const sagas = [
  takeLatest(signInAction.type, signInSaga),
  takeLatest(completeNewPasswordAction.type, completeNewPasswordSaga),
  takeLatest(forgotPasswordAction.type, forgotPasswordSaga),
  takeLatest(forgotPasswordSubmitAction.type, forgotPasswordSubmitSaga),
  takeLatest(signOutAction.type, signOutSaga),
  takeLatest(impersonateUserAction.type, takeIdentity),
  takeLatest(stopImpersonateUserAction.type, stopTakeIdentity),
  takeLatest(addApprobationsAction.type, addApprobations),
  call(startRefreshCurrentAccessMonitor)
];

export default sagas;
