import _ from 'lodash';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import Api from 'services/Api';
import {
  createClipWorkflowRequest as createClipWorkflowRequestAction,
  clipWorkflowRequestCreated as clipWorkflowRequestCreatedAction,
  clipWorkflowRequestError as clipWorkflowRequestErrorAction,
  searchWorkflowRequests as searchWorkflowRequestsAction,
  searchWorkflowRequestsLoadMore as searchWorkflowRequestsLoadMoreAction,
  workflowRequests as workflowRequestsAction,
  workflowRequestMoreLoaded as workflowRequestMoreLoadedAction,
  listWorkflowRequestsError as listWorkflowRequestsErrorAction,
  createWorkflowRequest as createWorkflowRequestAction,
  createWorkflowRequestError as createWorkflowRequestErrorAction,
  workflowRequestCreated as workflowRequestCreatedAction,
  updateWorkflowRequest as updateWorkflowRequestAction,
  updateWorkflowRequestError as updateWorkflowRequestErrorAction,
  workflowRequestUpdated as workflowRequestUpdatedAction,
  deleteWorkflowRequest as deleteWorkflowRequestAction,
  deleteWorkflowRequestError as deleteWorkflowRequestErrorAction,
  workflowRequestDeleted as workflowRequestDeletedAction,
  sendCommandToWorkflowRequest as sendCommandToWorkflowRequestAction,
  SearchWorkflowRequestsPayload,
  selectWorkflowRequestCount,
  selectWorkflowRequestSearchParams
} from 'redux/reducers/workflowRequest';
import { getRequestParams } from './appSagas';
import { handleMemberApiError, handleError } from 'services/Error';

export function* searchWorkflowRequests(action: SearchWorkflowRequestsPayload) {
  try {
    const params = _.omit(action.payload, 'refresh') || {};
    const requestParams = yield call(getRequestParams);
    const workflowRequests = yield call(
      Api.workflowRequests.searchWorkflowRequests,
      params,
      requestParams
    );
    yield put(workflowRequestsAction(workflowRequests));
  } catch (exception) {
    console.warn(exception);
    yield put(listWorkflowRequestsErrorAction(handleError(exception)));
  }
}

export function* searchWorkflowRequestsLoadMore(action: any) {
  try {
    const count = action.payload || 20;
    const workflowRequestCount = yield select(selectWorkflowRequestCount);
    const workflowRequestSearchParams = yield select(selectWorkflowRequestSearchParams);
    const params = {
      ...workflowRequestSearchParams,
      offset: (workflowRequestSearchParams.offset || 0) + workflowRequestCount,
      length: count,
      filters: workflowRequestSearchParams.filters || []
    };
    const requestParams = yield call(getRequestParams);
    const workflowRequests = yield call(
      Api.workflowRequests.searchWorkflowRequests,
      params,
      requestParams
    );
    yield put(workflowRequestMoreLoadedAction(workflowRequests));
  } catch (exception) {
    console.warn(exception);
    yield put(listWorkflowRequestsErrorAction(handleError(exception)));
  }
}

export function* createClipWorkflowRequest(action: any) {
  try {
    const {
      mediaResourceId,
      timecodeIn,
      timecodeOut,
      outputFormatId,
      clipName,
      mediaResourceVersion
    } = action.payload;
    const requestParams = yield call(getRequestParams);
    const workflowRequests = yield call(
      Api.workflowRequests.createWorkflowRequest as (data: any, params?: any) => Promise<any>,
      {
        profileIds: ['order.downloadFromVod'],
        tagInputs: [
          {
            fieldId: 'workflow',
            listValues: ['workflow.order.downloadFromVod']
          },
          {
            fieldId: 'parentMediaResourceId',
            stringValues: [mediaResourceId]
          },
          {
            fieldId: 'parentMediaResourceVersion',
            stringValues: [mediaResourceVersion || '0']
          },
          {
            fieldId: 'timecodeIn',
            stringValues: [timecodeIn]
          },
          {
            fieldId: 'timecodeOut',
            stringValues: [timecodeOut]
          },
          {
            fieldId: 'outputFormatId',
            stringValues: [outputFormatId]
          },
          {
            fieldId: 'clipName',
            stringValues: [clipName]
          }
        ]
      },
      requestParams
    );
    yield put(clipWorkflowRequestCreatedAction(workflowRequests));
  } catch (exception) {
    yield put(clipWorkflowRequestErrorAction(handleMemberApiError(exception)));
  }
}

export function* createWorkflowRequest(action: any) {
  try {
    const data = action.payload;
    const requestParams = yield call(getRequestParams);
    const workflowRequest = yield call(
      Api.workflowRequests.createWorkflowRequest,
      data,
      requestParams
    );
    yield put(workflowRequestCreatedAction(workflowRequest));
  } catch (exception) {
    console.warn(exception);
    yield put(createWorkflowRequestErrorAction(handleError(exception)));
  }
}

export function* updateWorkflowRequest(action: any) {
  try {
    const { id: workflowRequestId, ...data } = action.payload;
    const requestParams = yield call(getRequestParams);
    const workflowRequest = yield call(
      Api.workflowRequests.updateWorkflowRequest,
      workflowRequestId,
      data,
      requestParams
    );
    yield put(workflowRequestUpdatedAction(workflowRequest));
  } catch (exception) {
    console.warn(exception);
    yield put(updateWorkflowRequestErrorAction(handleError(exception)));
  }
}

export function* deleteWorkflowRequest(action: any) {
  try {
    const id = action.payload;
    const requestParams = yield call(getRequestParams);
    yield call(Api.workflowRequests.deleteWorkflowRequest, id, requestParams);
    yield put(workflowRequestDeletedAction(id));
  } catch (exception) {
    console.warn(exception);
    yield put(deleteWorkflowRequestErrorAction(handleError(exception)));
  }
}

export function* sendCommandToWorkflowRequest(action: any) {
  try {
    const { id: workflowRequestId, ...data } = action.payload;
    const requestParams = yield call(getRequestParams);
    const workflowRequest = yield call(
      Api.workflowRequests.sendCommandToWorkflowRequest,
      workflowRequestId,
      data,
      requestParams
    );
    yield put(workflowRequestUpdatedAction(workflowRequest));
  } catch (exception) {
    console.warn(exception);
    yield put(updateWorkflowRequestErrorAction(handleError(exception)));
  }
}

const sagas = [
  takeLatest(searchWorkflowRequestsAction.type, searchWorkflowRequests),
  takeLatest(searchWorkflowRequestsLoadMoreAction.type, searchWorkflowRequestsLoadMore),
  takeLatest(createClipWorkflowRequestAction.type, createClipWorkflowRequest),
  takeLatest(createWorkflowRequestAction.type, createWorkflowRequest),
  takeEvery(updateWorkflowRequestAction.type, updateWorkflowRequest),
  takeLatest(deleteWorkflowRequestAction.type, deleteWorkflowRequest),
  takeLatest(sendCommandToWorkflowRequestAction.type, sendCommandToWorkflowRequest)
];

export default sagas;
