import _ from 'lodash';
import { createSlice, PayloadAction, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { ListValue, SearchRequest, SearchResponse } from 'services/Api';
import {
  generateSearchState,
  generateRequestState,
  startRequest,
  setRequestError,
  SearchState,
  RequestState
} from 'utils/reducerHelpers';
import { Error } from 'services/Error';

const listValueAdapter = createEntityAdapter<ListValue>({
  selectId: (listValue: ListValue) => listValue.id
});

export const selectSearchListValuesRequestState = (state: any) =>
  state.listValue.request.searchListValues;
export const selectCreateOrUpdateListValueRequestState = (state: any) =>
  state.listValue.request.createOrUpdateListValue;
export const selectDeleteListValueRequestState = (state: any) =>
  state.listValue.request.deleteListValue;

const selectListValuesByProfileIdInState = (state: any, profileId: string) =>
  state.listValue.listValues[profileId];
export const selectListValues = createSelector(
  selectListValuesByProfileIdInState,
  listValuesByProfileId => {
    if (!listValuesByProfileId) return [];
    return listValuesByProfileId.loadedItems.ids.map(
      (id: string) => listValuesByProfileId.loadedItems.entities[id]
    );
  }
);
export const selectAddedListValues = createSelector(
  selectListValuesByProfileIdInState,
  listValuesByProfileId => {
    if (!listValuesByProfileId) return [];
    return listValuesByProfileId.addedItems.ids.map(
      (id: string) => listValuesByProfileId.addedItems.entities[id]
    );
  }
);
export const selectListValueById = createSelector(
  selectListValuesByProfileIdInState,
  (state, profileId, listValueId: string) => listValueId,
  (listValuesByProfileId, listValueId) =>
    !listValuesByProfileId ? {} : listValuesByProfileId.loadedItems.entities[listValueId]
);
export const selectListValueFacets = createSelector(
  selectListValuesByProfileIdInState,
  listValuesByProfileId => {
    if (!listValuesByProfileId) return {};
    return listValuesByProfileId.facets;
  }
);
export const selectListValueCount = createSelector(
  selectListValuesByProfileIdInState,
  listValuesByProfileId => (!listValuesByProfileId ? 0 : listValuesByProfileId.itemCount)
);
export const selectListValueTotalCount = createSelector(
  selectListValuesByProfileIdInState,
  listValuesByProfileId => (!listValuesByProfileId ? 0 : listValuesByProfileId.totalCount)
);
export const selectListValueSearchParams = createSelector(
  selectListValuesByProfileIdInState,
  listValuesByProfileId => {
    if (!listValuesByProfileId) return {};
    return listValuesByProfileId.searchParams;
  }
);
export const selectListValueSavedSearch = createSelector(
  selectListValuesByProfileIdInState,
  listValuesByProfileId => {
    if (!listValuesByProfileId) return {};
    return listValuesByProfileId.savedSearch;
  }
);

export type SearchListValuesPayload = PayloadAction<
  SearchRequest & { profileId: string; refresh?: boolean }
>;

type ListValueState = RequestState & {
  listValues: Record<string, SearchState<ListValue>>;
};

const initialState: ListValueState = {
  request: generateRequestState(
    ['searchListValues', 'createOrUpdateListValue', 'deleteListValue'],
    ['searchListValues']
  ),
  listValues: {}
};

const listValueSlice = createSlice({
  name: 'listValue',
  initialState,
  reducers: {
    searchListValues(state, action: SearchListValuesPayload) {
      const profileId: string = _.get(action.payload, 'profileId');
      const refresh = _.get(action.payload, 'refresh');
      const searchParams = _.omit(action.payload, ['profileId', 'refresh']);
      if (refresh) state.request.searchListValues.refreshing = true;
      else {
        state.request.searchListValues.loading = true;
        if (!state.listValues[profileId]) {
          state.listValues[profileId] = generateSearchState(listValueAdapter);
        }
        state.listValues[profileId].searchParams = searchParams;
      }
      state.request.searchListValues.error = undefined;
      listValueAdapter.removeAll(state.listValues[profileId].addedItems);
    },
    searchListValuesError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.searchListValues, action.payload);
    },
    listValues: {
      reducer(state, action: PayloadAction<SearchResponse & { profileId: string }>) {
        const { profileId, facets, items, itemCount, totalCount } = action.payload;
        listValueAdapter.setAll(state.listValues[profileId].loadedItems, items as ListValue[]);
        state.listValues[profileId].facets = facets!;
        state.listValues[profileId].itemCount = itemCount!;
        state.listValues[profileId].totalCount = totalCount;
        state.request.searchListValues.loading = false;
        state.request.searchListValues.refreshing = false;
      },
      prepare(profileId: string, searchResponse: SearchResponse) {
        return {
          payload: {
            profileId,
            ...searchResponse
          }
        };
      }
    },
    searchListValuesLoadMore: {
      reducer(state, action: PayloadAction<{ count: number; profileId: string }>) {
        startRequest(state.request.searchListValues);
      },
      prepare(count: number, profileId: string) {
        return {
          payload: {
            count,
            profileId
          }
        };
      }
    },
    moreLoadedListValues: {
      reducer(state, action: PayloadAction<SearchResponse & { profileId: string }>) {
        const { profileId, facets, items, itemCount } = action.payload;
        listValueAdapter.upsertMany(state.listValues[profileId].loadedItems, items as ListValue[]);
        state.listValues[profileId].facets = facets!;
        state.listValues[profileId].itemCount += itemCount || 0;
        state.listValues[profileId].itemCount = Math.min(
          state.listValues[profileId].itemCount,
          state.listValues[profileId].loadedItems.ids.length
        );
        state.request.searchListValues.loading = false;
      },
      prepare(profileId: string, searchResponse: SearchResponse) {
        return {
          payload: {
            profileId,
            ...searchResponse
          }
        };
      }
    },
    createOrUpdateListValue(state, action: PayloadAction<ListValue>) {
      startRequest(state.request.createOrUpdateListValue);
    },
    createOrUpdateListValueError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.createOrUpdateListValue, action.payload);
    },
    listValueUpserted(state, action: PayloadAction<ListValue>) {
      const { id, profileId } = action.payload;
      if (!state.listValues[profileId].loadedItems.entities[id]) {
        listValueAdapter.addOne(state.listValues[profileId].addedItems, action.payload);
        state.listValues[profileId].itemCount += 1;
        state.listValues[profileId].totalCount += 1;
      }
      listValueAdapter.upsertOne(state.listValues[profileId].loadedItems, action.payload);
      state.request.createOrUpdateListValue.loading = false;
    },
    deleteListValue: {
      reducer(state, action: PayloadAction<{ id: string; profileId: string }>) {
        startRequest(state.request.deleteListValue);
      },
      prepare(listValueId: string, profileId: string) {
        return {
          payload: {
            id: listValueId,
            profileId
          }
        };
      }
    },
    deleteListValueError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.deleteListValue, action.payload);
    },
    listValueDeleted(state, action: PayloadAction<{ id: string; profileId: string }>) {
      const { id, profileId } = action.payload;
      listValueAdapter.removeOne(state.listValues[profileId].addedItems, id);
      listValueAdapter.removeOne(state.listValues[profileId].loadedItems, id);
      state.listValues[profileId].itemCount -= 1;
      state.listValues[profileId].totalCount -= 1;
      state.request.deleteListValue.loading = false;
    },
    updateSavedSearch: {
      reducer(state, action: PayloadAction<Record<string, any>>) {
        const profileId = _.get(action.payload, 'profileId');
        const savedSearch = _.omit(action.payload, 'profileId');
        state.listValues[profileId].savedSearch = savedSearch;
      },
      prepare(profileId: string, savedSearch: Record<string, any>) {
        return {
          payload: {
            profileId,
            ...savedSearch
          }
        };
      }
    }
  }
});

export type { ListValue } from 'services/Api';
export const {
  searchListValues,
  searchListValuesError,
  listValues,
  searchListValuesLoadMore,
  moreLoadedListValues,
  createOrUpdateListValue,
  createOrUpdateListValueError,
  listValueUpserted,
  deleteListValue,
  deleteListValueError,
  listValueDeleted,
  updateSavedSearch
} = listValueSlice.actions;
export default listValueSlice.reducer;
