import { getOrganizationId } from '../tools/organizationTools';

async function loadTheme() {
  // @ts-ignore
  await import('bootstrap/dist/css/bootstrap.min.css');
  const organizationId = getOrganizationId() || window.location.hostname.split('.')[0];
  switch (organizationId) {
    case 'concacaf':
      await import(`../index-concacaf.less`);
      break;
    case 'lfp':
      await import(`../index-lfp.less`);
      break;
    default:
      await import(`../index-lfp.less`);
      break;
  }

  // we have to load layout after index to avoid issues
  await import(`../layout.less`);
}
export default loadTheme;
