import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './awsconfig';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import log from 'loglevel';

Amplify.configure(awsconfig);

type AuthType = {
  isAuthenticated: boolean;
  data: object | undefined;
};

const auth: AuthType = {
  isAuthenticated: false,
  data: undefined
};

onAuthUIStateChange((nextAuthState, authData) => {
  log.debug('auth.ts nextAuthState', nextAuthState);
  if (nextAuthState === AuthState.SignedIn) {
    auth.isAuthenticated = true;
    auth.data = authData;
  } else {
    auth.isAuthenticated = false;
  }
});

export async function isAuthenticated() {
  if (auth.isAuthenticated) return true;
  return !!(await Auth.currentAuthenticatedUser({
    bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  }));
}

export async function currentAuthenticatedUser() {
  return Auth.currentAuthenticatedUser({
    bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  });
}

export async function currentSession() {
  return Auth.currentSession();
}

export async function signIn({ username, password }: any) {
  try {
    const user = await Auth.signIn(username, password);
    return user;
  } catch (error) {
    log.debug('error signing in', error);
    throw error;
  }
}

export async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    log.debug('error signing out', error);
    throw error;
  }
}

export async function completeNewPassword({ user, password }: any) {
  try {
    return await Auth.completeNewPassword(user, password);
  } catch (error) {
    log.debug('error completing new password', error);
    throw error;
  }
}

export async function forgotPassword(email: string) {
  try {
    return await Auth.forgotPassword(email);
  } catch (error) {
    log.debug('error reseting password', error);
    throw error;
  }
}

export async function forgotPasswordSubmit(email: string, code: string, newPassword: string) {
  try {
    return Auth.forgotPasswordSubmit(email, code, newPassword);
  } catch (error) {
    log.debug('error reseting password', error);
    throw error;
  }
}

export async function refeshToken() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const session = await Auth.currentSession();
    return await new Promise((resolve, reject) => {
      user.refreshSession(session.getRefreshToken(), (error: any, session: any) => {
        if (error) reject(error);
        resolve(session);
      });
    });
  } catch (exception) {
    log.debug('unable to refresh token', exception);
    throw exception;
  }
}

export type { Subject, Action } from '@hbscloud/hekma-sdk';
export type { CognitoUserInterface } from '@aws-amplify/ui-components';
export { AuthState, ChallengeName } from '@aws-amplify/ui-components';
