import _ from 'lodash';
import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Permission } from 'services/Api';
import { Error } from 'services/Error';
import { RootState } from 'redux/reducers';
import {
  generateRequestState,
  generateSearchState,
  startRequest,
  setRequestError,
  RequestState,
  SearchState
} from 'utils/reducerHelpers';

const permissionAdapter = createEntityAdapter<Permission>({
  selectId: (permission: Permission) => permission.id
});

const permissionSelector = permissionAdapter.getSelectors<RootState>(
  state => state.permission.permissions.loadedItems
);

export const selectPermissions = permissionSelector.selectAll;
export const selectPermissionById = permissionSelector.selectById;
export const selectListPermissionsRequestState = (state: RootState) =>
  state.permission.request.list;

export const selectPermissionsByType = createSelector(
  selectPermissions,
  (state: RootState, type: string) => type,
  (permissions: Permission[], type: string) => {
    return _.filter(permissions, permission => _.toLower(permission.type) === _.toLower(type));
  }
);

export type PermissionReducerState = RequestState & {
  permissions: SearchState<Permission>;
};

const initialState: PermissionReducerState = {
  request: generateRequestState(['list', 'create', 'update', 'delete'], ['list']),
  permissions: generateSearchState(permissionAdapter)
};

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    listPermissions(state, action: PayloadAction<Record<string, any> | undefined>) {
      startRequest(state.request.list);
    },
    listPermissionsError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.list, action.payload);
    },
    permissionsListed(state, action: PayloadAction<Permission[]>) {
      permissionAdapter.setAll(state.permissions.loadedItems, action.payload);
      state.request.list.loading = false;
    }
  }
});

export const { listPermissions, listPermissionsError, permissionsListed } = permissionSlice.actions;
export type { Permission };
export default permissionSlice.reducer;
