import _ from 'lodash';
import moment from 'moment';
import { createSlice, PayloadAction, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import {
  Event,
  EventData,
  Log,
  LogFile,
  SearchResponse,
  MediaResource,
  OutputFormat,
  SearchRequest
} from 'services/Api';
import { RootState } from 'redux/reducers';
import { parseLog, getTagValueById } from 'utils/helpers';
import {
  RequestState,
  generateRequestState,
  setRequestError,
  startRequest
} from 'utils/reducerHelpers';

const eventAdapter = createEntityAdapter<Event>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: (event: Event) => event.id
});

const logAdapter = createEntityAdapter<Log>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: (log: Log) => log.id
});

const mediaResourceAdapter = createEntityAdapter<MediaResource>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: (mediaResource: MediaResource) => mediaResource.id
});

// type RootState = ReturnType<typeof configureStore>
// TODO: find the right type
const eventSelectors = eventAdapter.getSelectors<any>(state => state.event.events.items);

const mediaResourceSelectors = mediaResourceAdapter.getSelectors<any>(
  state => state.event.mediaResources
);

export const selectGetRequestState = (state: any) => state.event.request.get;
export const selectCreateRequestState = (state: any) => state.event.request.create;
export const selectUpdateRequestState = (state: any) => state.event.request.update;
export const selectDeleteRequestState = (state: any) => state.event.request.delete;
export const selectListRequestState = (state: any) => state.event.request.list;
export const selectListLogsRequestState = (state: any) => state.event.request.listLogs;
export const importBackupMediaResourceRequestState = (state: any) =>
  state.event.request.importBackupMediaResource;
export const selectUpdateMediaResourceRequestState = (state: any) =>
  state.event.request.updateMediaResource;
export const selectUpdateMediaResourceLiveRequestState = (state: any) =>
  state.event.request.updateMediaResourceLive;
export const selectDeleteMediaResourceRequestState = (state: any) =>
  state.event.request.deleteMediaResource;
export const selectListOutputFormatsRequestState = (state: any) =>
  state.event.request.listOutputFormats;
export const selectDownloadLogsRequestState = (state: RootState) =>
  state.event.request.downloadLogs;

export const selectEvents = eventSelectors.selectAll;
export const selectEventById = eventSelectors.selectById;
export const selectEventSearchParams = (state: any) => state.event.events.searchParams;
export const selectEventTotalCount = (state: any) => state.event.events.totalCount;
export const selectEventCount = (state: any) => state.event.events.itemCount;
export const selectEventFacets = (state: any) => state.event.events.facets;
export const selectEventsSavedSearch = (state: any) => state.event.events.savedSearch;

const selectLogsByEventIdInState = (state: any, eventId: string) =>
  state.event.logs.logsByEventId[eventId];
export const selectLogsByEventId = createSelector(selectLogsByEventIdInState, logsByEventId => {
  if (!logsByEventId) return [];
  return logsByEventId.items.ids
    .map((id: string) => logsByEventId.items.entities[id])
    .sort((a: any, b: any) => {
      const timeA = moment(a.datetimeIn).unix();
      const timeB = moment(b.datetimeIn).unix();
      const diff = timeA - timeB;
      if (diff === 0) {
        return a.type.localeCompare(b.type); // chapter before poi
      } else {
        return diff;
      }
    });
});
export const selectLogById = (state: any, eventId: string, id: string) => {
  return _.get(state, ['event', 'logs', 'logsByEventId', eventId, 'items', 'entities', id]);
};
export const selectLogsByEventIdCount = createSelector(selectLogsByEventIdInState, logsByEventId =>
  logsByEventId ? logsByEventId.itemCount : 0
);
export const selectLogsByEventIdCountRaw = createSelector(
  selectLogsByEventIdInState,
  logsByEventId => (logsByEventId ? logsByEventId.itemCountRaw : 0)
);
export const selectLogsByEventIdTotalCount = createSelector(
  selectLogsByEventIdInState,
  logsByEventId => (logsByEventId ? logsByEventId.totalCount : 0)
);
export const selectLogsByEventIdFacets = createSelector(selectLogsByEventIdInState, logsByEventId =>
  logsByEventId ? logsByEventId.facets : {}
);
export const selectLogFileDownloaded = createSelector(selectLogsByEventIdInState, logsByEventId =>
  logsByEventId ? logsByEventId.file : undefined
);
export const selectLogsSearchParams = (state: any) => state.event.logs.searchParams;

export const selectSearchEventsQuery = (state: any) => state.event.search.query;
export const selectSearchEventsRequestState = (state: any) => state.event.request.search;
export const selectSearchEventsResult = createSelector(
  (state: any) => state.event.search.result,
  (result: any) => {
    const { items, mode, facets, itemCount, totalCount } = result;
    const loadedItems =
      mode === 'events'
        ? items
        : mode === 'logs'
        ? _.map(_.groupBy(items, 'eventId'), (logs, eventId) => ({ eventId, logs }))
        : [];
    return {
      facets,
      mode,
      totalCount,
      items: loadedItems,
      itemCount
    };
  }
);
export const selectSearchLogsByEventId = (state: any, eventId: string) => {
  const { items, mode } = selectSearchEventsResult(state);
  if (mode !== 'logs') return [];
  return items.find((item: any) => item.eventId === eventId).logs;
};

export const selectMediaResourceById = mediaResourceSelectors.selectById;
const selectMediaResourcesByEventIdInState = (state: any, eventId: string) =>
  state.event.mediaResourcesByEventId[eventId];
export const selectMediaResourcesFromListEvents = (state: any, eventId: string) =>
  state.event.mediaResourcesForListEvents[eventId];
export const selectSelectedMediaResourcesFromHome = (state: any) =>
  state.event.selectedMediaResourcesFromHome;

export const selectMediaResourcesByEventId = createSelector(
  selectMediaResourcesByEventIdInState,
  mediaResources => {
    if (!mediaResources) return [];
    return mediaResources.ids.map((id: string) => mediaResources.entities[id]);
  }
);

export const selectClipFormatByMediaResourceId = createSelector(
  (state: RootState, mediaResourceId: string) =>
    state.event.clipFormatsByMediaResourceId[mediaResourceId],
  clipFormat => clipFormat || []
);

export type EventAddedPayload = PayloadAction<Event>;
export type EventsPayload = PayloadAction<SearchResponse>;
export type LogsPayload = PayloadAction<Log[]>;
export type Error = { code: string; error: any } | undefined;
export type ErrorPayload = PayloadAction<Error>;

export type EventReducerState = RequestState & {
  events: {
    facets?: Record<string, Record<string, number>>;
    items: ReturnType<typeof eventAdapter.getInitialState>;
    itemCount: number | undefined;
    totalCount: number;
    searchParams: SearchRequest | undefined;
    savedSearch: Record<string, any>;
  };
  mediaResourcesByEventId: any;
  mediaResourcesForListEvents: any;
  selectedMediaResourcesFromHome: any;
  mediaResources: ReturnType<typeof mediaResourceAdapter.getInitialState>;
  clipFormatsByMediaResourceId: Record<string, OutputFormat[]>;
  logs: {
    logsByEventId: {
      [k: string]: {
        facets?: Record<string, Record<string, number>>;
        file?: string;
        items: any;
        itemCount: number | undefined;
        itemCountRaw: number | undefined;
        totalCount: number;
      };
    };
    searchParams: SearchRequest;
  };
  search: {
    query: {
      current: any;
      last: any;
    };
    result: {
      facets?: Record<string, Record<string, number>>;
      items: Array<any>;
      itemCount: number | undefined;
      totalCount: number;
      mode: 'events' | 'logs' | undefined;
    };
  };
};

const initialState: EventReducerState = {
  request: generateRequestState(
    [
      'list',
      'get',
      'create',
      'update',
      'delete',
      'search',
      'updateMediaResource',
      'updateMediaResourceLive',
      'deleteMediaResource',
      'listLogs',
      'listOutputFormats',
      'downloadLogs'
    ],
    ['list', 'listLogs']
  ),
  events: {
    facets: {},
    items: eventAdapter.getInitialState(),
    itemCount: 0,
    totalCount: 0,
    searchParams: {
      filters: []
    },
    savedSearch: {}
  },
  mediaResourcesByEventId: {},
  mediaResourcesForListEvents: {},
  selectedMediaResourcesFromHome: undefined,
  mediaResources: mediaResourceAdapter.getInitialState(),
  clipFormatsByMediaResourceId: {},
  logs: {
    logsByEventId: {},
    searchParams: {
      filters: []
    }
  },
  search: {
    query: {
      current: {},
      last: {}
    },
    result: {
      items: [],
      itemCount: 0,
      totalCount: 0,
      mode: undefined,
      facets: {}
    }
  }
};

// TODO : typing
const eventSlice: any = createSlice({
  name: 'event',
  initialState,
  reducers: {
    listEvents(state, action: PayloadAction<(SearchRequest & { refresh?: boolean }) | undefined>) {
      const refresh = _.get(action.payload, 'refresh');
      const searchParams = _.omit(action.payload, 'refresh');
      if (refresh) {
        state.request.list.refreshing = true;
      } else {
        state.request.list.loading = true;
        state.events.searchParams = searchParams;
      }
      state.request.list.error = undefined;
    },
    listEventsLoadMore(state, action) {
      state.request.list.loading = true;
    },
    listEventsError(state, action: ErrorPayload) {
      setRequestError(state.request.list, action.payload);
    },
    events(state, action: EventsPayload) {
      const { items, itemCount, totalCount, facets } = action.payload;
      eventAdapter.setAll(state.events.items, items as Event[]);
      state.request.list.loading = false;
      state.request.list.refreshing = false;
      state.events.itemCount = itemCount;
      state.events.totalCount = totalCount;
      state.events.facets = facets;
    },
    moreLoadedEvents(state, action: EventsPayload) {
      const { items, itemCount, totalCount, facets } = action.payload;
      eventAdapter.addMany(state.events.items, items as Event[]);
      state.request.list.loading = false;
      state.events.itemCount! += itemCount || 0;
      state.events.totalCount = totalCount;
      state.events.facets = facets;
    },
    addEvent(state, action: PayloadAction<Event>) {
      startRequest(state.request.create);
    },
    addEventError(state, action: ErrorPayload) {
      setRequestError(state.request.create, action.payload);
    },
    eventAdded(state, action: EventAddedPayload) {
      eventAdapter.addOne(state.events.items, action.payload);
      state.request.create.loading = false;
      state.events.itemCount! += 1;
      state.events.totalCount! += 1;
    },
    updateEvent(state, action: PayloadAction<{ id: string } & EventData>) {
      startRequest(state.request.update);
    },
    updateEventError(state, action: ErrorPayload) {
      setRequestError(state.request.update, action.payload);
    },
    eventUpdated(state, action) {
      eventAdapter.updateOne(state.events.items, {
        id: action.payload.id,
        changes: action.payload
      });
      state.request.update.loading = false;
    },
    deleteEvent(state, action: PayloadAction<Event>) {
      startRequest(state.request.delete);
    },
    deleteEventError(state, action: ErrorPayload) {
      setRequestError(state.request.delete, action.payload);
    },
    eventDeleted(state, action: PayloadAction<string>) {
      eventAdapter.removeOne(state.events.items, action.payload);
      state.request.delete.loading = false;
    },
    getEvent(state, action: PayloadAction<string>) {
      startRequest(state.request.get);
    },
    getEventError(state, action: ErrorPayload) {
      setRequestError(state.request.get, action.payload);
    },
    eventRetrieved(state, action) {
      eventAdapter.addOne(state.events.items, action.payload);
      state.request.get.loading = false;
    },
    getMediaResourcesById(state, action: PayloadAction<string>) {},
    searchMediaResourcesForListEvents(state, action: PayloadAction<string>) {},
    mediaResourcesForListEvents: {
      reducer(state, action: PayloadAction<{ listEvents: string[]; mediaResponse: any }>) {
        const { mediaResponse } = action.payload;
        const mediaResourcesGroupedByEventID = _.groupBy(mediaResponse.items, 'eventId');
        state.mediaResourcesForListEvents = mediaResourcesGroupedByEventID;
        state.request.list.loading = false;
        state.request.list.refreshing = false;
      },
      prepare: (listEvents: string[], mediaResponse: any) => ({
        payload: {
          listEvents,
          mediaResponse
        }
      })
    },
    getMediaResourcesForListEventsError(state, action) {
      setRequestError(state.request.list, action.payload);
    },
    getMediaResourcesByEventId: {
      reducer(
        state,
        action: PayloadAction<{
          eventId: string;
          options?: Omit<MediaResource, 'eventId'> & { refresh?: boolean };
        }>
      ) {
        const refresh = _.get(action.payload.options, 'refresh');
        if (refresh) {
          state.request.list.refreshing = true;
        } else {
          state.request.list.loading = true;
        }
        state.request.list.error = undefined;
      },
      prepare(eventId: string, options?: Omit<MediaResource, 'eventId'> & { refresh?: boolean }) {
        return {
          payload: {
            eventId,
            options
          }
        };
      }
    },
    getMediaResourcesByEventIdError(state, action) {
      setRequestError(state.request.list, action.payload);
    },
    mediaResourcesByEventId: {
      reducer(state, action: PayloadAction<{ eventId: string; mediaResources: MediaResource[] }>) {
        const { eventId, mediaResources } = action.payload;
        const sortedMediaResources = _.sortBy(mediaResources, resource => {
          const videoSource = getTagValueById(resource, 'videoSource');
          const videoContent = getTagValueById(resource, 'videoContent');
          const videoSourceSortPriorityIndex =
            getTagValueById(videoSource, 'sortPriorityIndex') || 0;
          const videoContentSortPriorityIndex =
            getTagValueById(videoContent, 'sortPriorityIndex') || 0;
          return (
            -1 * (parseInt(videoSourceSortPriorityIndex) + parseInt(videoContentSortPriorityIndex))
          );
        });
        state.mediaResourcesByEventId[eventId] = mediaResourceAdapter.setAll(
          { ...state.mediaResourcesByEventId[eventId] } || mediaResourceAdapter.getInitialState(),
          sortedMediaResources
        );
        mediaResourceAdapter.upsertMany(state.mediaResources, mediaResources);
        state.request.list.loading = false;
        state.request.list.refreshing = false;
      },
      prepare: (eventId: string, mediaResources: MediaResource[]) => ({
        payload: {
          eventId,
          mediaResources
        }
      })
    },
    getClipFormatByMediaResourceId(state, action: PayloadAction<string | string[]>) {
      startRequest(state.request.listOutputFormats);
    },
    getClipFormatByMediaResourceIdError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.listOutputFormats, action.payload);
    },
    clipFormatByMediaResource: {
      reducer(
        state,
        action: PayloadAction<{ mediaResourceId: string; clipFormats: OutputFormat[] }>
      ) {
        const { mediaResourceId, clipFormats } = action.payload;
        return {
          ...state,
          request: {
            ...state.request,
            listOutputFormats: {
              loading: false,
              error: undefined
            }
          },
          clipFormatsByMediaResourceId: {
            ...state.clipFormatsByMediaResourceId,
            [mediaResourceId]: clipFormats
          }
        };
      },
      prepare: (mediaResourceId: string, clipFormats: OutputFormat[]) => ({
        payload: {
          mediaResourceId,
          clipFormats
        }
      })
    },
    listLogsByEventId(
      state,
      action: PayloadAction<SearchRequest & { eventId: string; refresh?: boolean }>
    ) {
      const { eventId, refresh, ...rest } = action.payload;
      if (refresh) {
        state.request.listLogs.refreshing = true;
      } else {
        state.request.listLogs.loading = true;
        state.logs.searchParams = {
          ...rest,
          filters: _.unionBy(
            action.payload?.filters || [],
            [
              {
                name: 'eventId',
                operator: '=',
                value: eventId
              }
            ] as any,
            'name'
          )
        };
      }
      state.request.listLogs.error = undefined;
    },
    listLogsByEventIdError(state, action) {
      setRequestError(state.request.listLogs, action.payload);
    },
    logs: {
      reducer(state, action: PayloadAction<any>) {
        const {
          eventId,
          logs: { items: unfilteredLogs, itemCount, totalCount, facets }
        } = action.payload;
        const logs = unfilteredLogs.filter((log: any) => !log.id.includes('NO_LOG'));
        logs.sort((a: Log, b: Log) => {
          const timeA = moment(a.datetimeIn).unix();
          const timeB = moment(b.datetimeIn).unix();
          const diff = timeA - timeB;
          if (diff === 0) {
            return a.type.localeCompare(b.type); // chapter before poi
          } else {
            return diff;
          }
        });
        const newState: any = (state.logs.logsByEventId = {
          ...state.logs.logsByEventId
        });
        if (!newState[eventId]) {
          newState[eventId] = {
            facets: {},
            items: logAdapter.getInitialState(),
            itemCount: 0,
            totalCount: 0,
            technicalLog: undefined
          };
        }
        // TODO: typing
        newState[eventId] = {
          facets,
          items: {
            ids: logs.map((item: any) => item.id),
            entities: logs.reduce((entities: any, item: any) => {
              entities[item.id] = parseLog(item);
              return entities;
            }, {})
          },
          // TODO: better handling for item count because of the technical log
          itemCount: logs.length < itemCount ? logs.length : itemCount,
          itemCountRaw: itemCount,
          totalCount: logs.length < itemCount ? totalCount - 1 : totalCount
        };
        state.logs.logsByEventId = newState;
        state.request.listLogs.loading = false;
        state.request.listLogs.refreshing = false;
      },
      prepare: (logs: any, eventId: string) => ({
        payload: {
          logs,
          eventId
        }
      })
    },
    listLogsByEventIdLoadMore(state, action) {
      startRequest(state.request.listLogs);
    },
    moreLoadedLogs: {
      reducer(state, action: PayloadAction<{ eventId: string; logs: any }>) {
        const {
          eventId,
          logs: { items: unfilteredLogs, itemCount, totalCount }
        } = action.payload;
        const logs = unfilteredLogs.filter((log: any) => !log.id.includes('NO_LOG'));
        logs.sort((a: Log, b: Log) => {
          const timeA = moment(a.datetimeIn).unix();
          const timeB = moment(b.datetimeIn).unix();
          const diff = timeA - timeB;
          if (diff === 0) {
            return a.type.localeCompare(b.type); // chapter before poi
          } else {
            return diff;
          }
        });
        state.logs.logsByEventId[eventId].items.ids.push(..._.map(logs, 'id'));
        _.merge(
          state.logs.logsByEventId[eventId].items.entities,
          logs.reduce((entities: any, item: any) => {
            entities[item.id] = parseLog(item);
            return entities;
          }, {})
        );
        state.logs.logsByEventId[eventId].itemCount! +=
          logs.length < itemCount ? logs.length : itemCount;
        state.logs.logsByEventId[eventId].itemCountRaw! += itemCount;
        if (logs.length < itemCount) {
          state.logs.logsByEventId[eventId].totalCount = totalCount - 1;
        }
        state.request.listLogs.loading = false;
      },
      prepare(eventId, logs) {
        return {
          payload: {
            eventId,
            logs
          }
        };
      }
    },
    addLog(state, action) {
      startRequest(state.request.create);
    },
    addLogError(state, action: ErrorPayload) {
      setRequestError(state.request.create, action.payload);
    },
    logAdded(state, action: PayloadAction<Log>) {
      const { id, eventId } = action.payload;
      state.logs.logsByEventId[eventId].items.ids.push(id);
      state.logs.logsByEventId[eventId].items.entities[id] = parseLog(action.payload);
      state.logs.logsByEventId[eventId].itemCount! += 1;
      state.logs.logsByEventId[eventId].itemCountRaw! += 1;
      state.logs.logsByEventId[eventId].totalCount += 1;
      state.request.create.loading = false;
    },
    updateLog(state, action) {
      startRequest(state.request.update);
    },
    updateLogError(state, action) {
      setRequestError(state.request.update, action.payload);
    },
    logUpdated(state, action) {
      const { eventId, id } = action.payload;
      state.logs.logsByEventId[eventId].items.entities[id] = parseLog(action.payload);
      state.request.update.loading = false;
    },
    deleteLog(state, action) {
      startRequest(state.request.delete);
    },
    deleteLogError(state, action) {
      setRequestError(state.request.delete, action.payload);
    },
    logDeleted: {
      reducer(state, action: PayloadAction<{ eventId: string; logId: string }>) {
        const { eventId, logId } = action.payload;
        state.logs.logsByEventId[eventId].items.ids.splice(
          state.logs.logsByEventId[eventId].items.ids.indexOf(logId),
          1
        );
        delete state.logs.logsByEventId[eventId].items.entities[logId];
        state.logs.logsByEventId[eventId].itemCount! -= 1;
        state.logs.logsByEventId[eventId].itemCountRaw! -= 1;
        state.logs.logsByEventId[eventId].totalCount -= 1;
        state.request.delete.loading = false;
      },
      prepare: (eventId, logId) => ({
        payload: {
          eventId,
          logId
        }
      })
    },
    downloadLogs(state, action: PayloadAction<string>) {
      startRequest(state.request.downloadLogs);
    },
    downloadLogsError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.downloadLogs, action.payload);
    },
    logsDownloaded: {
      reducer(state, action: PayloadAction<{ eventId: string; logFile: LogFile }>) {
        const { eventId, logFile } = action.payload;
        if (!state.logs.logsByEventId[eventId]) {
          state.logs.logsByEventId[eventId] = {
            facets: {},
            items: logAdapter.getInitialState(),
            itemCount: 0,
            itemCountRaw: 0,
            totalCount: 0
          };
        }
        state.logs.logsByEventId[eventId].file = logFile.file;
        state.request.downloadLogs.loading = false;
      },
      prepare(eventId: string, logFile: LogFile) {
        return {
          payload: {
            eventId,
            logFile
          }
        };
      }
    },
    setLastSearchEventsQuery(state, action) {
      state.search.query.last = action.payload;
    },
    setSelectedMediaResourcesFromHome(state, action) {
      state.selectedMediaResourcesFromHome = action.payload;
    },
    searchEvents(state, action) {
      startRequest(state.request.search);
      state.search.query.current = action.payload;
    },
    searchEventsLoadmore(state, action: PayloadAction<number | undefined>) {
      startRequest(state.request.search);
    },
    moreLoadedSearchEvents(state, action) {
      const { items, itemCount } = action.payload;
      state.request.search.loading = false;
      state.search.result.itemCount += itemCount;
      state.search.result.items.push(...items);
    },
    searchEventsSuccess(state, action: PayloadAction<SearchResponse>) {
      const { facets, items, itemCount, totalCount } = action.payload;
      const mode =
        !items || !items.length
          ? undefined
          : items.find((item: any) => item.tags?.find((tag: any) => tag.id === 'keywords'))
          ? 'logs'
          : 'events';
      state.request.search.loading = false;
      state.search.result.facets = facets;
      state.search.result.items = items;
      state.search.result.itemCount = itemCount;
      state.search.result.totalCount = totalCount;
      state.search.result.mode = mode;
    },
    searchEventsFail(state, action) {
      setRequestError(state.request.search, action.payload);
    },
    updateMediaResource(state, action) {
      startRequest(state.request.updateMediaResource);
    },
    updateMediaResourceError(state, action: ErrorPayload) {
      setRequestError(state.request.updateMediaResource, action.payload);
    },
    mediaResourceUpdated: {
      reducer(state, action: PayloadAction<{ eventId: string; mediaResource: MediaResource }>) {
        const { eventId, mediaResource } = action.payload;
        state.mediaResourcesByEventId[eventId] = mediaResourceAdapter.updateOne(
          state.mediaResourcesByEventId[eventId],
          { id: mediaResource.id, changes: mediaResource }
        );
        mediaResourceAdapter.updateOne(state.mediaResources, {
          id: mediaResource.id,
          changes: mediaResource
        });
        state.request.updateMediaResource.loading = false;
      },
      prepare: (eventId: string, mediaResource: MediaResource) => ({
        payload: {
          eventId,
          mediaResource
        }
      })
    },
    updateMediaResourceLive(state, action) {
      startRequest(state.request.updateMediaResourceLive);
    },
    updateMediaResourceLiveError(state, action: ErrorPayload) {
      setRequestError(state.request.updateMediaResourceLive, action.payload);
    },
    mediaResourceLiveUpdated: {
      reducer(state, action: PayloadAction<{ eventId: string; mediaResource: MediaResource }>) {
        const { eventId, mediaResource } = action.payload;
        state.mediaResourcesByEventId[eventId] = mediaResourceAdapter.updateOne(
          state.mediaResourcesByEventId[eventId],
          { id: mediaResource.id, changes: mediaResource }
        );
        mediaResourceAdapter.updateOne(state.mediaResources, {
          id: mediaResource.id,
          changes: mediaResource
        });
        state.request.updateMediaResource.loading = false;
      },
      prepare: (eventId: string, mediaResource: MediaResource) => ({
        payload: {
          eventId,
          mediaResource
        }
      })
    },
    deleteMediaResource(state, action: PayloadAction<MediaResource>) {
      startRequest(state.request.deleteMediaResource);
    },
    deleteMediaResourceError(state, action: ErrorPayload) {
      setRequestError(state.request.deleteMediaResource, action.payload);
    },
    mediaResourceDeleted: {
      reducer(state, action: PayloadAction<{ eventId: string; mediaResourceId: string }>) {
        const { eventId, mediaResourceId } = action.payload;
        state.mediaResourcesByEventId[eventId] = mediaResourceAdapter.removeOne(
          state.mediaResourcesByEventId[eventId],
          mediaResourceId
        );
        mediaResourceAdapter.removeOne(state.mediaResources, mediaResourceId);
        state.request.deleteMediaResource.loading = false;
      },
      prepare: (eventId: string, mediaResourceId: string) => ({
        payload: {
          eventId,
          mediaResourceId
        }
      })
    },
    clearRequestState(state, action: PayloadAction<string>) {
      if (_.has(state.request, action.payload)) {
        _.set(state.request as any, [action.payload, 'loading'], false);
        _.set(state.request as any, [action.payload, 'error'], undefined);
      }
    },
    updateEventsSavedSearch(state, action) {
      state.events.savedSearch = action.payload;
    }
  }
});

export const {
  logs,
  listLogsByEventId,
  listLogsByEventIdError,
  listLogsByEventIdLoadMore,
  moreLoadedLogs,
  addLog,
  addLogError,
  logAdded,
  updateLog,
  updateLogError,
  logUpdated,
  deleteLog,
  deleteLogError,
  logDeleted,
  downloadLogs,
  downloadLogsError,
  logsDownloaded,
  events,
  moreLoadedEvents,
  listEvents,
  listEventsLoadMore,
  listEventsError,
  addEvent,
  eventAdded,
  addEventError,
  getEvent,
  eventRetrieved,
  getEventError,
  updateEvent,
  updateEventError,
  eventUpdated,
  deleteEvent,
  deleteEventError,
  eventDeleted,
  getMediaResourcesById,
  getMediaResourcesByEventId,
  searchMediaResourcesForListEvents,
  getMediaResourcesByEventIdError,
  getMediaResourcesForListEventsError,
  mediaResourcesByEventId,
  mediaResourcesForListEvents,
  getClipFormatByMediaResourceId,
  getClipFormatByMediaResourceIdError,
  clipFormatByMediaResource,
  setLastSearchEventsQuery,
  setSelectedMediaResourcesFromHome,
  searchEvents,
  searchEventsLoadmore,
  moreLoadedSearchEvents,
  searchEventsSuccess,
  searchEventsFail,
  updateMediaResource,
  updateMediaResourceError,
  mediaResourceUpdated,
  updateMediaResourceLive,
  updateMediaResourceLiveError,
  mediaResourceLiveUpdated,
  deleteMediaResource,
  deleteMediaResourceError,
  mediaResourceDeleted,
  clearRequestState,
  updateEventsSavedSearch
} = eventSlice.actions;
export type { Event, Log, MediaResource } from 'services/Api';
export default eventSlice.reducer;
