import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import Api, { FacetSearchRequest } from 'services/Api';
import { handleError } from 'services/Error';
import {
  listEventFacets as listEventFacetsAction,
  listEventFacetsError as listEventFacetsErrorAction,
  eventFacets as eventFacetsAction
} from 'redux/reducers/facet';
import { getRequestParams } from './appSagas';

export function* listEventFacets(action: PayloadAction<FacetSearchRequest>) {
  try {
    const params = action.payload;
    const requestParams = yield call(getRequestParams);
    const facets = yield call(Api.search.facetSearch, params, requestParams);
    yield put(eventFacetsAction(params.name!, facets));
  } catch (exception) {
    console.warn(exception);
    yield put(listEventFacetsErrorAction(handleError(exception)));
  }
}

const sagas = [takeLatest(listEventFacetsAction.type, listEventFacets)];

export default sagas;
