const locales = {
  en: {
    app: require('./en/translation.json')
  },
  fr: {
    app: require('./fr/translation.json')
  }
};

export default locales;
