import { createSlice, PayloadAction, createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { CreditTransaction, CreditTransactionData } from 'services/Api';
import {
  generateRequestState,
  startRequest,
  setRequestError,
  RequestState
} from 'utils/reducerHelpers';
import { Error } from 'services/Error';
import { RootState } from './index';

const creditTransactionAdapter = createEntityAdapter<CreditTransaction>({
  selectId: (creditTransaction: CreditTransaction) => creditTransaction.id
});

export const selectCreateCreditTransactionRequestState = (state: RootState) =>
  state.creditTransaction.request.createCreditTransaction;

type CreditTransactionState = RequestState & {
  creditTransactions: EntityState<CreditTransaction>;
};

const initialState: CreditTransactionState = {
  request: generateRequestState(['createCreditTransaction'], []),
  creditTransactions: creditTransactionAdapter.getInitialState()
};

const creditTransactionSlice = createSlice({
  name: 'creditTransaction',
  initialState,
  reducers: {
    createCreditTransaction(state, action: PayloadAction<CreditTransactionData>) {
      startRequest(state.request.createCreditTransaction);
    },
    createCreditTransactionError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.createCreditTransaction, action.payload);
    },
    creditTransactionCreated(state, action: PayloadAction<CreditTransaction>) {
      creditTransactionAdapter.addOne(state.creditTransactions, action.payload);
      state.request.createCreditTransaction.loading = false;
    }
  }
});

export type { CreditTransaction, CreditTransactionData } from 'services/Api';
export const { createCreditTransaction, createCreditTransactionError, creditTransactionCreated } =
  creditTransactionSlice.actions;
export default creditTransactionSlice.reducer;
