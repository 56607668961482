import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import Api, { SearchRequest } from 'services/Api';
import {
  listMediaResources as listMediaResourcesAction,
  searchMediaResources as searchMediaResourcesAction,
  searchMediaResourcesError as searchMediaResourcesErrorAction,
  mediaResources as mediaResourcesAction,
  getMediaResource as getMediaResourceAction,
  getMediaResourceError as getMediaResourceErrorAction,
  mediaResourceRetrieved as mediaResourceRetrievedAction
} from 'redux/reducers/resource';
import { getRequestParams } from './appSagas';
import _ from 'lodash';
import { handleError } from 'services/Error';

export function* listMediaResources(action: any) {
  try {
    const params = _.omit(action.payload, 'refresh');
    const requestParams = yield call(getRequestParams);
    const mediaResources = yield call(Api.resources.getResources, params as any, requestParams);
    yield put(
      mediaResourcesAction({
        items: mediaResources,
        itemCount: mediaResources.length,
        totalCount: mediaResources.length
      })
    );
  } catch (exception) {
    console.warn(exception);
    yield put(searchMediaResourcesErrorAction(handleError(exception)));
  }
}

export function* searchMediaResources(action: PayloadAction<SearchRequest>) {
  try {
    const params = action.payload;
    const requestParams = yield call(getRequestParams);
    const mediaResources = yield call(Api.resources.searchResources, params, requestParams);
    yield put(mediaResourcesAction(mediaResources));
  } catch (exception) {
    console.warn(exception);
    yield put(searchMediaResourcesErrorAction(handleError(exception)));
  }
}

export function* getMediaResource(action: PayloadAction<string>) {
  try {
    const resourceId = action.payload;
    const requestParams = yield call(getRequestParams);
    const mediaResource = yield call(Api.resources.getResource, resourceId, requestParams);
    yield put(mediaResourceRetrievedAction(mediaResource));
  } catch (exception) {
    console.warn(exception);
    yield put(getMediaResourceErrorAction(handleError(exception)));
  }
}

const sagas = [
  takeLatest(listMediaResourcesAction.type, listMediaResources),
  takeLatest(searchMediaResourcesAction.type, searchMediaResources),
  takeLatest(getMediaResourceAction.type, getMediaResource)
];

export default sagas;
