import { organizationId } from '../tools/organizationTools';

export type Language = {
  name: string;
  code: string;
};

export const languages: Language[] = [
  {
    name: 'english',
    code: 'en'
  },
  {
    name: 'french',
    code: 'fr'
  }
];

export const getDefaultLanguage = () => {
  return organizationId === 'lfp' ? 'fr' : 'en';
};
