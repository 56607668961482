import { createSlice, PayloadAction, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { Member, Role, SearchRequest, SearchResponse } from 'services/Api';
// import configureStore from 'redux/store'
import { selectProfileById } from 'redux/reducers/profiles';
import {
  RequestState,
  generateRequestState,
  setRequestError,
  startRequest
} from 'utils/reducerHelpers';

const memberAdapter = createEntityAdapter<Member>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: (member: Member) => member.id
});

const roleAdapter = createEntityAdapter<Role>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: (role: Role) => role.id
});

// type RootState = ReturnType<typeof configureStore>
// TODO: find the right type
const memberSelectors = memberAdapter.getSelectors<any>(state => state.member.members.items);
const roleSelectors = roleAdapter.getSelectors<any>(state => state.member.roles);

export const memberProfileSelector = (state: any) => selectProfileById(state, 'members');
export const selectMembers = memberSelectors.selectAll;
export const selectMemberById = memberSelectors.selectById;
export const selectMemberSearchParams = (state: any) => state.member.members.searchParams;
export const selectMemberTotalCount = (state: any) => state.member.members.totalCount;
export const selectMemberCount = (state: any) => state.member.members.items.ids.length;
export const selectMemberSearchFacets = (state: any) => state.member.members.facets;
export const selectMemberSavedSearch = (state: any) => state.member.members.savedSearch;
export const selectRoles = roleSelectors.selectAll;
export const selectRoleById = roleSelectors.selectById;
export const selectRoleMap = (state: any) => state.member.roles.entities;
export const selectCreateRequestState = (state: any) => state.member.request.create;
export const selectListRequestState = (state: any) => state.member.request.list;
export const selectSearchRequestState = (state: any) => state.member.request.list;
export const selectUpdateRequestState = (state: any) => state.member.request.update;
export const selectDeleteRequestState = (state: any) => state.member.request.delete;

export const selectMembersWithRoles = createSelector(
  selectMembers,
  state => state.member.roles.entities,
  (members, roleEntities) => {
    return members.map(member => ({
      ...member,
      roles: member.roleIds?.map(roleId => roleEntities[roleId]),
      club: member.tags
        ?.filter(tag => (tag as any).id === 'club')
        .reduce((club, item): any => {
          const values = item.listValues?.map(listValue => listValue.value);
          return values?.join(',');
        }, '')
    }));
  }
);
export const selectMemberByIdWithRoles = createSelector(
  (state: any, id: string) => selectMemberById(state, id),
  selectRoleMap,
  (member, roleEntities) => {
    return !member
      ? undefined
      : {
          ...member,
          roles: member?.roleIds?.map(roleId => roleEntities[roleId])
        };
  }
);

export type MemberAddedPayload = PayloadAction<Member>;
export type MembersPayload = PayloadAction<SearchResponse>;
export type SearchMembersPayload = PayloadAction<
  (SearchRequest & { refresh?: boolean }) | undefined
>;
export type RolesPayload = PayloadAction<Role[]>;
export type Error = { code: string; message?: any } | undefined;
export type ErrorPayload = PayloadAction<Error>;

type initialStateType = RequestState & {
  members: {
    facets?: Record<string, Record<string, number>>;
    items: ReturnType<typeof memberAdapter.getInitialState>;
    totalCount: number;
    searchParams: SearchRequest;
    savedSearch: Record<string, any>;
  };
  roles: ReturnType<typeof roleAdapter.getInitialState>;
};

const initialState: initialStateType = {
  request: generateRequestState(['list', 'create', 'update', 'delete'], ['list']),
  members: {
    facets: {},
    items: memberAdapter.getInitialState(),
    totalCount: 0,
    searchParams: {
      filters: []
    },
    savedSearch: {}
  },
  roles: roleAdapter.getInitialState()
};

const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    searchMembers(state, action: SearchMembersPayload) {
      const { refresh, ...searchParams } = action.payload || {};
      if (refresh) {
        state.request.list.refreshing = true;
      } else {
        state.request.list.loading = true;
        if (searchParams) state.members.searchParams = searchParams;
      }
      state.request.list.error = undefined;
    },
    searchMemberLoadMore(state, action: PayloadAction<number | undefined>) {
      startRequest(state.request.list);
    },
    listMembers(state, action: PayloadAction<undefined>) {
      startRequest(state.request.list);
    },
    listMembersError(state, action: ErrorPayload) {
      setRequestError(state.request.list, action.payload);
    },
    members(state, action: MembersPayload) {
      const { items, totalCount, facets } = action.payload;
      memberAdapter.setAll(state.members.items, items as Member[]);
      state.members.facets = facets;
      state.members.totalCount = totalCount;
      state.request.list.loading = false;
      state.request.list.refreshing = false;
    },
    moreLoaded(state, action: MembersPayload) {
      const { items, totalCount } = action.payload;
      memberAdapter.addMany(state.members.items, items as Member[]);
      state.members.totalCount = totalCount;
      state.request.list.loading = false;
    },
    addMember(state, action: PayloadAction<Member>) {
      startRequest(state.request.create);
    },
    addMemberError(state, action: ErrorPayload) {
      setRequestError(state.request.create, action.payload);
    },
    memberAdded(state, action: MemberAddedPayload) {
      memberAdapter.upsertOne(state.members.items, action.payload);
      state.request.create.loading = false;
      state.members.totalCount += 1;
    },
    activateMember(state, action: PayloadAction<Member>) {
      startRequest(state.request.update);
    },
    updateMember(state, action: PayloadAction<Member>) {
      startRequest(state.request.update);
    },
    updateMemberError(state, action: ErrorPayload) {
      setRequestError(state.request.update, action.payload);
    },
    memberUpdated(state, action: MemberAddedPayload) {
      memberAdapter.upsertOne(state.members.items, action.payload);
      state.request.update.loading = false;
    },
    deleteMember(state, action: PayloadAction<string>) {
      startRequest(state.request.delete);
    },
    deleteMemberError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.delete, action.payload);
    },
    memberDeleted(state, action: PayloadAction<string>) {
      memberAdapter.removeOne(state.members.items, action.payload);
      state.request.delete.loading = false;
      state.members.totalCount -= 1;
    },
    listRoles(state, action: PayloadAction<undefined>) {},
    roles(state, action: RolesPayload) {
      roleAdapter.setAll(state.roles, action.payload);
    },
    updateSavedSearch(state, action) {
      state.members.savedSearch = action.payload;
    }
  }
});

export const {
  listRoles,
  roles,
  members,
  searchMembers,
  searchMemberLoadMore,
  moreLoaded,
  listMembers,
  listMembersError,
  addMember,
  memberAdded,
  addMemberError,
  activateMember,
  updateMember,
  updateMemberError,
  memberUpdated,
  deleteMember,
  deleteMemberError,
  memberDeleted,
  updateSavedSearch
} = memberSlice.actions;
export type { Member, Role } from 'services/Api';
export default memberSlice.reducer;
