import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/reducers';

export const selectBackgroundTasks = (state: RootState) => state.app.backgroundTasks;
export const selectBackgroundProfileAndListValueRefresh = (state: RootState) =>
  state.app.backgroundTasks.refreshProfileAndListValue || false;

type AppReducerState = {
  ready: boolean;
  backgroundTasks: Record<string, boolean>;
};

const initialState: AppReducerState = {
  ready: false,
  backgroundTasks: {}
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    ready(state, action: PayloadAction<boolean>) {
      state.ready = action.payload;
    },
    startBackgroundTokenRefresh(state) {
      state.backgroundTasks.refreshToken = true;
    },
    stopBackgroundTokenRefresh(state) {
      state.backgroundTasks.refreshToken = false;
    },
    startBackgroundProfileAndListValueRefresh(state, action: PayloadAction<undefined>) {
      state.backgroundTasks.refreshProfileAndListValue = true;
    },
    stopBackgroundProfileAndListValueRefresh(state, action: PayloadAction<undefined>) {
      state.backgroundTasks.refreshProfileAndListValue = false;
    },
    startBackgroundCurrentAccessRefresh(state) {
      state.backgroundTasks.refreshCurrentAccess = true;
    },
    stopBackgroundCurrentAccessRefresh(state) {
      state.backgroundTasks.refreshCurrentAccess = false;
    }
  }
});

export const {
  ready,
  startBackgroundTokenRefresh,
  stopBackgroundTokenRefresh,
  startBackgroundProfileAndListValueRefresh,
  stopBackgroundProfileAndListValueRefresh,
  startBackgroundCurrentAccessRefresh,
  stopBackgroundCurrentAccessRefresh
} = appSlice.actions;

export default appSlice.reducer;
