import { call, put, takeLatest } from 'redux-saga/effects';
import Api from 'services/Api';
import { handleError } from 'services/Error';
import {
  listPermissions as listPermissionsAction,
  listPermissionsError as listPermissionsErrorAction,
  permissionsListed as permissionsListedAction
} from 'redux/reducers/permission';
import { getRequestParams } from './appSagas';

export function* listPermissions(action: any) {
  try {
    const query = action.payload;
    const requestParams = yield call(getRequestParams);
    const permissions = yield call(Api.permissions.getPermissions, query, requestParams);
    yield put(permissionsListedAction(permissions));
  } catch (exception) {
    console.warn(exception);
    yield put(listPermissionsErrorAction(handleError(exception)));
  }
}

const sagas = [takeLatest(listPermissionsAction.type, listPermissions)];

export default sagas;
