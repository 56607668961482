import { all, call, cancel, delay, fork, put, take, takeEvery } from 'redux-saga/effects';
import Api from 'services/Api';
import { handleError } from 'services/Error';
import {
  startBackgroundProfileAndListValueRefresh,
  stopBackgroundProfileAndListValueRefresh
} from 'redux/reducers/app';
import {
  listProfiles as listProfilesAction,
  listProfilesError as listProfilesErrorAction,
  profiles as profilesAction,
  getProfileById as getProfileByIdAction,
  getProfileByIdError as getProfileByIdErrorAction,
  profile as profileAction,
  listListValuesError as listListValuesErrorAction,
  listValues as listValuesAction,
  listValuesByProfileId as listValuesByProfileIdAction,
  listValuesByProfileIdError as listValuesByProfileIdErrorAction,
  listValue as listValueAction
} from 'redux/reducers/profiles';
import { getRequestParams } from './appSagas';

export function* listProfiles(action: any) {
  try {
    const requestParams = yield call(getRequestParams);
    const profiles = yield call(Api.profiles.getProfiles, action.payload, requestParams);
    yield put(profilesAction(profiles));
  } catch (exception) {
    console.warn(exception);
    yield put(listProfilesErrorAction(handleError(exception)));
  }
}

export function* getProfileById(action: any) {
  try {
    const requestParams = yield call(getRequestParams);
    const profileId = action.payload;
    const profile = yield call(Api.profiles.getProfile, profileId, requestParams);
    yield put(profileAction(profile));
    return profile;
  } catch (exception) {
    console.warn(exception);
    yield put(getProfileByIdErrorAction(handleError(exception)));
  }
}

export function* listAllListValues() {
  try {
    const requestParams = yield call(getRequestParams);
    let {
      itemCount,
      totalCount,
      items: listValues
    } = yield call(Api.listValues.searchListValues, {}, requestParams);
    while (itemCount < totalCount) {
      const data = { offset: itemCount };
      const searchResponse = yield call(Api.listValues.searchListValues, data, requestParams);
      itemCount += searchResponse.itemCount;
      listValues = [...listValues, ...searchResponse.items];
    }
    yield put(listValuesAction(listValues));
  } catch (exception) {
    console.warn(exception);
    yield put(listListValuesErrorAction(handleError(exception)));
  }
}

export function* getListValueByProfileId(action: any) {
  try {
    const profileId: string = action.payload;
    const requestParams = yield call(getRequestParams);
    const listValue = yield call(
      Api.listValues.getListValues,
      { profileId } as any /* required because of hekma-sdk typing */,
      requestParams
    );
    yield put(listValueAction(listValue, profileId));
    return listValue;
  } catch (exception) {
    console.warn(exception);
    yield put(listValuesByProfileIdErrorAction(handleError(exception)));
  }
}

function* refreshProfilesAndListValuesBg(timeToLive: number) {
  while (true) {
    yield all([call(listProfiles, {}), call(listAllListValues)]);
    yield delay(timeToLive * 1000);
  }
}

export function* startRefreshProfileAndListValue() {
  while (yield take(startBackgroundProfileAndListValueRefresh.type)) {
    const bgRefreshProfileAndListValue = yield fork(refreshProfilesAndListValuesBg, 60 * 60);

    yield take(stopBackgroundProfileAndListValueRefresh.type);
    yield cancel(bgRefreshProfileAndListValue);
  }
}

const sagas = [
  takeEvery(listProfilesAction.type, listProfiles),
  takeEvery(listValuesByProfileIdAction.type, getListValueByProfileId),
  takeEvery(getProfileByIdAction.type, getProfileById),
  call(startRefreshProfileAndListValue)
];

export default sagas;
