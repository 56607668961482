import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Toast = {
  id: number;
  autoClose?: boolean;
  type: string;
  title: string;
  message: string;
};

type ToastState = {
  toasts: Array<Toast>;
};

const initialState: ToastState = {
  toasts: []
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast: {
      reducer(state, action: PayloadAction<Toast>) {
        state.toasts.push(action.payload);
      },
      prepare: (type: string, title: string, message: string, autoClose = true) => ({
        payload: {
          id: +new Date(),
          autoClose,
          type,
          title,
          message
        }
      })
    },
    removeToast(state, action) {
      state.toasts.splice(
        state.toasts.findIndex(toast => toast.id === action.payload),
        1
      );
    }
  }
});

export const { addToast, removeToast } = toastSlice.actions;
export default toastSlice.reducer;
