import { createEntityAdapter, createSlice, PayloadAction, EntityState } from '@reduxjs/toolkit';
import { MonitoringResponse, MonitoringItem, SearchRequest } from 'services/Api';
import { Error } from 'services/Error';
import { RootState } from 'redux/reducers/index';
import {
  RequestState,
  SearchState,
  generateRequestState,
  setRequestError,
  startRequest
} from 'utils/reducerHelpers';

const monitoringItemAdapter = createEntityAdapter<MonitoringItem>({
  selectId: (monitoringItem: MonitoringItem) => monitoringItem.id
});

const monitoringSelector = monitoringItemAdapter.getSelectors<RootState>(
  state => state.monitoring.search.items
);

export const selectMonitorings = monitoringSelector.selectAll;
export const selectMonitoringHeaderGroups = (state: RootState) =>
  state.monitoring.search.headerGroups;
export const selectMonitoringHeaders = (state: RootState) => state.monitoring.search.headers;

export const selectSearchMonitoringRequestState = (state: RootState) =>
  state.monitoring.request.list;
export const selectMonotoringItemCount = (state: RootState) => state.monitoring.search.itemCount;
export const selectMonitoringItemTotalCount = (state: RootState) =>
  state.monitoring.search.totalCount;
export const selectMonitoringFacets = (state: RootState) => state.monitoring.search.facets;
export const selectMonitoringSearchParams = (state: RootState) =>
  state.monitoring.search.searchParams;
export const selectMonitoringSavedSearch = (state: RootState) =>
  state.monitoring.search.savedSearch;

export type MonitoringReducerState = RequestState & {
  search: Omit<MonitoringResponse, 'rows'> &
    Pick<SearchState<undefined>, 'searchParams' | 'savedSearch'> & {
      items: EntityState<MonitoringItem>;
    };
};

const initialState: MonitoringReducerState = {
  request: generateRequestState(['list'], []),
  search: {
    facets: {},
    headerGroups: [],
    headers: [],
    items: monitoringItemAdapter.getInitialState(),
    itemCount: 0,
    totalCount: 0,
    searchParams: {},
    savedSearch: {}
  }
};

const monitoringSlice = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    searchMonitoring(state, action: PayloadAction<SearchRequest & { refresh?: boolean }>) {
      const refresh = action.payload?.refresh;
      if (refresh) {
        state.request.list.refreshing = true;
        state.request.list.error = undefined;
      } else {
        startRequest(state.request.list);
        state.search.searchParams = action.payload;
      }
    },
    searchMonitoringError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.list, action.payload);
    },
    monitoring(state, action: PayloadAction<MonitoringResponse>) {
      const { facets, headerGroups, headers, rows, itemCount, totalCount } = action.payload;
      monitoringItemAdapter.setAll(state.search.items, rows || []);
      state.request.list.loading = false;
      state.request.list.refreshing = false;
      state.search.facets = facets;
      state.search.headerGroups = headerGroups;
      state.search.headers = headers;
      state.search.itemCount = itemCount;
      state.search.totalCount = totalCount;
    },
    searchMonitoringLoadMore(state, action) {
      startRequest(state.request.list);
    },
    moreLoadedMonitoring(state, action) {
      const { facets, headerGroups, headers, rows, itemCount, totalCount } = action.payload;
      monitoringItemAdapter.addMany(state.search.items, rows);
      state.request.list.loading = false;
      state.search.facets = facets;
      state.search.headerGroups = headerGroups;
      state.search.headers = headers;
      state.search.itemCount! += itemCount || 0;
      state.search.totalCount = totalCount;
    },
    updateMonitoringSavedSearch(state, action) {
      state.search.savedSearch = action.payload;
    }
  }
});

export type {
  MonitoringHeaderGroup,
  MonitoringHeader,
  MonitoringHeaderItem,
  MonitoringItem
} from 'services/Api';
export const {
  searchMonitoring,
  searchMonitoringError,
  searchMonitoringLoadMore,
  monitoring,
  moreLoadedMonitoring,
  updateMonitoringSavedSearch
} = monitoringSlice.actions;
export default monitoringSlice.reducer;
