import _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import Api, { RoleData, SearchRequest } from 'services/Api';
import { handleError } from 'services/Error';
import {
  searchRoles as searchRolesAction,
  searchRolesError as searchRolesErrorAction,
  rolesSearched as rolesSearchedAction,
  searchRolesLoadMore as searchRolesLoadMoreAction,
  moreLoadedRoles as moreLoadedRolesAction,
  createRole as createRoleAction,
  createRoleError as createRoleErrorAction,
  roleCreated as roleCreatedAction,
  updateRole as updateRoleAction,
  updateRoleError as updateRoleErrorAction,
  roleUpdated as roleUpdatedAction,
  deleteRole as deleteRoleAction,
  deleteRoleError as deleteRoleErrorAction,
  roleDeleted as roleDeletedAction,
  selectRoleCount,
  selectRoleSearchParams,
  selectAddedRoleCount
} from 'redux/reducers/role';
import { getRequestParams } from './appSagas';

export function* searchRoles(action: PayloadAction<SearchRequest>) {
  try {
    const params = _.omit(action.payload, 'refresh');
    const requestParams = yield call(getRequestParams);
    const searchResponse = yield call(Api.roles.searchRoles, params, requestParams);
    yield put(rolesSearchedAction(searchResponse));
  } catch (exception) {
    console.warn(exception);
    yield put(searchRolesErrorAction(handleError(exception)));
  }
}

export function* searchRolesLoadMore(action: PayloadAction<number>) {
  try {
    const count = action.payload;
    const roleCount = yield select(selectRoleCount);
    const addedRoleCount = yield select(selectAddedRoleCount);
    const searchParams = yield select(selectRoleSearchParams);
    const params = {
      ...searchParams,
      offset: (searchParams.offset || 0) + (roleCount - addedRoleCount),
      length: count,
      filters: searchParams.filters || []
    };
    const requestParams = yield call(getRequestParams);
    const searchResponse = yield call(Api.roles.searchRoles, params, requestParams);
    yield put(moreLoadedRolesAction(searchResponse));
  } catch (exception) {
    console.warn(exception);
    yield put(searchRolesErrorAction(handleError(exception)));
  }
}

export function* createRole(action: PayloadAction<RoleData>) {
  try {
    const data = action.payload;
    const requestParams = yield call(getRequestParams);
    const role = yield call(Api.roles.createRole, data, requestParams);
    yield put(roleCreatedAction(role));
  } catch (exception) {
    console.warn(exception);
    yield put(createRoleErrorAction(handleError(exception)));
  }
}

export function* updateRole(action: PayloadAction<RoleData & { id: string }>) {
  try {
    const { id, ...data } = action.payload;
    const requestParams = yield call(getRequestParams);
    const role = yield call(Api.roles.updateRole, id, data, requestParams);
    yield put(roleUpdatedAction(role));
  } catch (exception) {
    console.warn(exception);
    yield put(updateRoleErrorAction(handleError(exception)));
  }
}

export function* deleteRole(action: PayloadAction<string>) {
  try {
    const roleId = action.payload;
    const requestParams = yield call(getRequestParams);
    yield call(Api.roles.deleteRole, roleId, requestParams);
    yield put(roleDeletedAction(roleId));
  } catch (exception) {
    console.warn(exception);
    yield put(deleteRoleErrorAction(handleError(exception)));
  }
}

const sagas = [
  takeLatest(searchRolesAction.type, searchRoles),
  takeLatest(searchRolesLoadMoreAction.type, searchRolesLoadMore),
  takeLatest(createRoleAction.type, createRole),
  takeLatest(updateRoleAction.type, updateRole),
  takeLatest(deleteRoleAction.type, deleteRole)
];

export default sagas;
