import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

export default function configureAppStore() {
  // TODO: find the right type
  // const store = configureStore<state, AnyAction, Middleware<>()

  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware, ...getDefaultMiddleware()]
  });

  sagaMiddleware.run(sagas);

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers/index', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  return store;
}
