import _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import Api, { SearchRequest } from 'services/Api';
import { handleError } from 'services/Error';
import {
  searchMonitoring as searchMonitoringAction,
  searchMonitoringError as searchMonitoringErrorAction,
  searchMonitoringLoadMore as searchMonitoringLoadMoreAction,
  monitoring as monitoringAction,
  moreLoadedMonitoring as moreLoadedMonitoringAction,
  selectMonotoringItemCount,
  selectMonitoringSearchParams
} from 'redux/reducers/monitoring';
import { getRequestParams } from './appSagas';

export function* searchMonitoring(action: PayloadAction<SearchRequest & { refresh?: boolean }>) {
  try {
    const data = _.omit(action.payload, 'refresh');
    const requestParams = yield call(getRequestParams);
    const monitoringResponse = yield call(Api.monitoring.searchMonitoring, data, requestParams);
    yield put(monitoringAction(monitoringResponse));
  } catch (exception) {
    console.warn(exception);
    yield put(searchMonitoringErrorAction(handleError(exception)));
  }
}

export function* searchMonitoringLoadMore(action: PayloadAction<number>) {
  try {
    const count = action.payload || 20;
    const monitoringItemCount = yield select(selectMonotoringItemCount);
    const searchParams = yield select(selectMonitoringSearchParams);
    const data = {
      ...searchParams,
      length: count,
      offset:
        searchParams.offset !== undefined
          ? searchParams.offset + monitoringItemCount
          : monitoringItemCount,
      filters: searchParams.filters || []
    };
    const requestParams = yield call(getRequestParams);
    const monitoringResponse = yield call(Api.monitoring.searchMonitoring, data, requestParams);
    yield put(moreLoadedMonitoringAction(monitoringResponse));
  } catch (exception) {
    console.warn(exception);
    yield put(searchMonitoringErrorAction(handleError(exception)));
  }
}

const sagas = [
  takeLatest(searchMonitoringAction.type, searchMonitoring),
  takeLatest(searchMonitoringLoadMoreAction.type, searchMonitoringLoadMore)
];

export default sagas;
